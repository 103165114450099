import React from 'react'
import './About.scss'
const About = () => {
    //const [expanded, setExpanded] = useState(false)
    return(
        <div className='about'>
            <div className='card-content'>
                Barnevernsfaglig kvalitetssystem (BFK) er et rammeverk som gir barnevernsfaglig og juridisk støtte til 
                barnevernstjenestenes arbeid med barnevernssaker, og som primært retter seg mot det skjønnsmessige 
                handlingsrommet. Rammeverket implementeres i nye fagsystemer, og blir dermed direkte tilgjengelig for 
                saksbehandler underveis i barnevernssakens forløp. Det samme innholdet som implementeres i fagsystemene 
                vises også på denne nettsiden.
            </div> 
        </div>
    )
}

export default About;