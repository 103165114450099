import React from 'react';

import Tidslinje from '../tidslinje/Tidslinje';
import Oppgaver from '../oppgaver/Oppgaver';
import KunnskapselementerPanel from '../kunnskapselementer-panel/KunnskapselementerPanel';

import './MainContent.scss';

import useStore from '../../store/use-store';

/**
 * A content wrapper for the application that displays the error messages at top.
 * @param {Props} param0 Children elements to display
 */

const MainContent = () => {
  const { activeFile } = useStore();

  if (activeFile) {
    return (
      <>
        <div className="columns">
          <div className="column">
            <Tidslinje />
          </div>
        </div>
        <div className="columns columns--isContent">
          <div className="column">
            <Oppgaver />
          </div>
          <div className="column">
            <KunnskapselementerPanel />
          </div>
        </div>
      </>
    );
  }
  return <></>;
};

export default MainContent;
