import DOMPurify from "dompurify";
import PropTypes from "prop-types";

const sanitizeHtml = (html) => ({
  __html: DOMPurify.sanitize(html)
})

sanitizeHtml.propTypes = {
  html: PropTypes.string.isRequired,
};

export default sanitizeHtml