import React from 'react';

import {
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Card,
  Tooltip,
} from '@material-ui/core';

import { PROPTYPE_PUBLIKASJON } from '../../store/element-proptypes';

import InnholdselementIkon from '../innholdselementikon/InnholdselementIkon';
import InnholdselementWarningIcon from '../innholdselement-warning-icon/InnholdselementWarningIcon';
import { DevAndQaOnly, DevOnly, IsProdEnvironment } from '../predicate-display/PredicateDisplay';

/**
 * This is a component displaying a innholdselement of type Publikasjon.
 *  publikasjon       = Props to be displayed
 *  inExpandMode      = Tells the innholdselement if it is shown in an expanded window
 *  borderColor       = Border color of the element (for list level use)
 *  backgroundColor   = Background color for the element
 *  isDuplicate       = Boolean saying if there is an element with the same ID as this one
 * @param {Props} param0 Props to display a Publikasjon
 * @returns A innholdselement of type Publikasjon
 */
const Publikasjon = ({
  publikasjon,
  inExpandMode,
  borderColor,
  backgroundColor,
  isDuplicate,
}) => {
  const { id, url, navn, utgiver, beskrivelse } = publikasjon;
  const getTooptip = () => (
    <>
      <p>ID: {id}</p>
      <p>Publikasjon</p>
    </>
  );
  return (
    <Card
      style={{
        border:
          borderColor !== 'none' ? `2px solid ${borderColor}` : borderColor,
        background: inExpandMode ? backgroundColor : 'none',
        backgroundColor: inExpandMode ? backgroundColor : 'none',
      }}
    >
      <Button href={url} target="_blank" key={id}>
        <ListItem>
          <Tooltip
            title={getTooptip()}
            placement="left"
            enterDelay={300}
            leaveDelay={0}
            disableFocusListener={IsProdEnvironment()}
            disableHoverListener={IsProdEnvironment()}
            disableTouchListener={IsProdEnvironment()}
          >
            <ListItemAvatar>
              <div>
                <InnholdselementIkon
                  elementType="publikasjon"
                  elementObject={publikasjon}
                  color="#737373"
                />
                {isDuplicate && (
                  <DevAndQaOnly>
                    <InnholdselementWarningIcon tooltipText="Dette elementet finnes flere steder i denne listen" />
                  </DevAndQaOnly>
                )}
              </div>
            </ListItemAvatar>
          </Tooltip>
          <ListItemText secondary={`${utgiver} - ${beskrivelse}`}>
            {navn}
          </ListItemText>
        </ListItem>
      </Button>
    </Card>
  );
};

Publikasjon.propTypes = PROPTYPE_PUBLIKASJON;

export default Publikasjon;
