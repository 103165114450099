/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { generateRandomId } from '../../store/helper-methods';
import { BORDER_LEVEL_COLORS } from '../../store/constants';

import Kilde from '../innholdselementer/Kilde';
import Kunnskapstekst from '../innholdselementer/Kunnskapstekst';
import Liste from '../innholdselementer/Liste';
import Sporsmal from '../innholdselementer/Sporsmal';
import Publikasjon from '../innholdselementer/Publikasjon';

import './InnholdselementerListe.scss';

/**
 *  A component that renders out the innholdselement that is passed in as props.
 *
 *  innholdselementer     = The list of elements related to the wanted list.
 *  listlevel             = The level of which the list is displayed. If the list is on
 *                          level 1 it is the top level list inside 'Global Kunnskapstekster'
 *                          or 'Kunnskap & Veiledning'. If if is level 2, it is a list
 *                          inside one of the list elements ('Sporsmal' can have a list of tekstreferanser).
 *  inExpandMode          = Tells if it is shown in an expanded window
 *
 * @param {object} props Props used to display InnholdselementListe
 * @returns A component displaying relevant innholdselementer.
 */

const InnholdselementerListe = ({
  innholdselementer,
  listLevel,
  inExpandMode = false,
}) => {
  const [expandedInnholdselement, setExpandedInnholdselement] = useState(false);
  const [checkedBoolskSporsmal, setCheckedBoolskSporsmal] = React.useState([]);

  /**
   * This function handles the expand/collapse of all list elements.
   * Only one can be expanded at a time.
   * @param {string} id id of innholdselement
   */
  const handleExpand = (id) => {
    setExpandedInnholdselement(id === expandedInnholdselement ? false : id);
  };

  /**
   * This function keeps a track of which Boolsk sporsmal that is checked.
   * @param {string} id ID of a Boolsk sporsmal-element
   */
  const handleBoolskSporsmalToggle = (id) => {
    const currentIndex = checkedBoolskSporsmal.indexOf(id);
    const newChecked = [...checkedBoolskSporsmal];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedBoolskSporsmal(newChecked);
  };

  /**
   * This function returns an ID prefix for every element in the list.
   * @param {string} type RefType of element
   * @param {number} id ID of innholdselement
   * @returns String
   */
  const generateListId = (type, id) => `${type}_${id}_${listLevel}`;

  /**
   * This is a list of duplicated ID's in the list to keep track of
   * duplicated elements.
   */
  const duplicatedInnholdselementer = innholdselementer
    .map((e) => generateListId(e.refType, e.id))
    .filter(
      (e) =>
        innholdselementer.filter((d) => generateListId(d.refType, d.id) === e)
          .length > 1,
    );

  // const getBorderColor = () => INNHOLDSELEMENT_BORDER_COLOR;
  const getBorderColor = () => BORDER_LEVEL_COLORS[listLevel - 1];
  const getBackgroundColor = () => '#FFFFFF';

  /**
   * This function checks what kind of component that is asked for and
   * returns the correct one. These are added to the list.
   * @param {object} elementObject Innholdselement object
   * @param {boolean} isExpanded Boolean saying if element is expanded (if the
   * component has that attribute).
   * @returns A list element component
   */
  const getInnholdselementComponent = (elementObject, isExpanded) => {
    if (elementObject.refType === 'kilde') {
      return (
        <Kilde
          kilde={elementObject}
          inExpandMode={inExpandMode}
          borderColor={getBorderColor()}
          backgroundColor={getBackgroundColor()}
          isDuplicate={
            duplicatedInnholdselementer.indexOf(
              generateListId(elementObject.refType, elementObject.id),
            ) > -1
          }
        />
      );
    }
    if (elementObject.refType === 'kunnskapstekst') {
      return (
        <Kunnskapstekst
          kunnskapstekst={elementObject}
          handleExpand={(id) => handleExpand(id)}
          expanded={isExpanded}
          listLevel={listLevel}
          inExpandMode={inExpandMode}
          borderColor={getBorderColor()}
          backgroundColor={getBackgroundColor()}
          isDuplicate={
            duplicatedInnholdselementer.indexOf(
              generateListId(elementObject.refType, elementObject.id),
            ) > -1
          }
        />
      );
    }
    if (elementObject.refType === 'liste') {
      return (
        <Liste
          liste={elementObject}
          handleExpand={(id) => handleExpand(id)}
          expanded={isExpanded}
          inExpandMode={inExpandMode}
          borderColor={getBorderColor()}
          backgroundColor={getBackgroundColor()}
          isDuplicate={
            duplicatedInnholdselementer.indexOf(
              generateListId(elementObject.refType, elementObject.id),
            ) > -1
          }
        />
      );
    }
    if (elementObject.refType === 'sporsmal') {
      return (
        <Sporsmal
          sporsmal={elementObject}
          handleExpand={(id) => handleExpand(id)}
          handleBoolskSporsmalToggleFunc={(id) =>
            handleBoolskSporsmalToggle(id)
          }
          checkedBoolskSporsmal={
            checkedBoolskSporsmal.indexOf(elementObject.id) !== -1
          }
          expanded={isExpanded}
          listLevel={listLevel}
          inExpandMode={inExpandMode}
          borderColor={getBorderColor()}
          backgroundColor={getBackgroundColor()}
          isDuplicate={
            duplicatedInnholdselementer.indexOf(
              generateListId(elementObject.refType, elementObject.id),
            ) > -1
          }
        />
      );
    }
    if (elementObject.refType === 'publikasjon') {
      return (
        <Publikasjon
          publikasjon={elementObject}
          listLevel={listLevel}
          inExpandMode={inExpandMode}
          borderColor={getBorderColor()}
          backgroundColor={getBackgroundColor()}
          isDuplicate={
            duplicatedInnholdselementer.indexOf(
              generateListId(elementObject.refType, elementObject.id),
            ) > -1
          }
        />
      );
    }

    return null;
  };

  return (
    <div className="innholdselementliste__wrapper">
      {/* {innholdselementer.length > 0 && (
        <h3>
          <b>Tekstreferanser</b>
        </h3>
      )} */}
      <ul className="innholdselementliste">
        {innholdselementer.length > 0 &&
          innholdselementer.map((c) => {
            const innholdselementComponent = getInnholdselementComponent(
              c,
              c.id === expandedInnholdselement,
            );
            return (
              <div
                className={`innholdselementliste__listeelement 
                innholdselementliste__listeelement--${c.refType} 
                innholdselementliste__listeelement--level${listLevel}
                ${
                  duplicatedInnholdselementer.indexOf(
                    generateListId(c.refType, c.id),
                  ) > -1
                    ? 'innholdselementliste__listeelement--duplicate'
                    : 'innholdselementliste__listeelement--notduplicate'
                }`}
                key={`${generateListId(c.refType, c.id)}_${generateRandomId()}`}
              >
                {innholdselementComponent}
              </div>
            );
          })}
      </ul>
    </div>
  );
};

InnholdselementerListe.propTypes = {
  innholdselementer: PropTypes.arrayOf(PropTypes.object).isRequired,
  listLevel: PropTypes.number,
  inExpandMode: PropTypes.bool,
};

InnholdselementerListe.defaultProps = {
  listLevel: 1,
  inExpandMode: false,
};

export default InnholdselementerListe;
