import React from 'react';
import {
  Accordion,
  AccordionDetails,
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { PROPTYPE_OMRADE } from '../../store/element-proptypes';

import InnholdselementIkon from '../innholdselementikon/InnholdselementIkon';

import useStore from '../../store/use-store';

import InnholdselementerListe from '../innholdselementer-liste/InnholdselementerListe';

import { DevOnly, IsProdEnvironment } from '../predicate-display/PredicateDisplay';

/**
 * Omrade is a part of Dimensjon.
 *  omrade            = Props to be displayed
 *  isExpanded        = Boolean saying if the dimensjon is expanded or not
 *  handleExpandFunc  = Handle function to toggle the dimensjon
 *  color             = Color of the icons based on which dimensjon it is in
 * @param {Props} param0 Props to display a Omrade
 * @returns A Omrade component
 */
const Omrade = ({ omrade, isExpanded, handleExpandFunc, color }) => {
  const { getTekstreferanser } = useStore();
  const { id, navn, beskrivelse, tekstreferanser } = omrade;

  const getTooptip = () => (
    <>
      <p>ID: {id}</p>
      <p>Område</p>
    </>
  );

  return (
    <Accordion
      className={`omraderliste__omrade omraderliste__omrade--${id}`}
      expanded={isExpanded}
    >
      <Button target="_blank" key={id} onClick={() => handleExpandFunc(id)}>
        <ListItem>
          <Tooltip
            title={getTooptip()}
            placement="left"
            enterDelay={300}
            leaveDelay={0}
            disableFocusListener={IsProdEnvironment()}
            disableHoverListener={IsProdEnvironment()}
            disableTouchListener={IsProdEnvironment()}
          >
            <ListItemAvatar>
              <InnholdselementIkon
                elementType="omrade"
                elementObject={omrade}
                color={color}
              />
            </ListItemAvatar>
          </Tooltip>
          <ListItemText secondary={beskrivelse}>{navn}</ListItemText>
        </ListItem>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <AccordionDetails>
        {isExpanded && (
          <InnholdselementerListe
            innholdselementer={getTekstreferanser(tekstreferanser)}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

Omrade.propTypes = PROPTYPE_OMRADE;

export default Omrade;
