import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

/**
 * To warn the user about something within the innholdselement,
 * we use this icon with a tooltip message.
 * @param {Props} param0 tooltipText is the text displayed in the tooltip
 * @returns A warning icon with a tooltip message.
 */
const InnholdselementWarningIcon = ({ tooltipText }) => (
  <Tooltip title={tooltipText} placement="left">
    <span className="tooltip__kilde">
      <FontAwesomeIcon
        color="#65A4BF"
        size="2x"
        icon={['far', 'exclamation-triangle']}
        style={{ margin: '8px' }}
      />
    </span>
  </Tooltip>
);

InnholdselementWarningIcon.propTypes = {
  tooltipText: PropTypes.string.isRequired,
};

export default InnholdselementWarningIcon;
