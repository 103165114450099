export const SVAR_DATA_TYPE_STRINGS = {
  'IngenSvar' : 'Reflekterende spørsmål',
  'Boolsk' : 'Ja / Nei',
  'Tekst' : 'Tekstlig svar',
  'ListeEnvalg' : 'En-valg fra liste',
  'ListeFlervalg' : 'Fler-valg fra liste',
};

export const INNHOLDSELEMENT_BORDER_COLOR = '#359857';

export const BORDER_LEVEL_COLORS = [
  'none',
  '#ABC9BA', // Green (4)
  '#C7C4B1', // Sand (2)
  '#80CCD3', // Aqua (3)
  '#F0E8B4', // Ocre (3)
  '#B3B3B3', // Neutral (4)
  '#B3A3B9', // Violet (4)
];

export const BACKGROUND_LEVEL_COLORS = [
  'none',
  '#E1EBE4', // Green (5)
  '#F2F1ED', // Sand (4)
  '#BFE6E9', // Aqua (4)
  '#FFFEF5', // Ocre (5)
  '#D9D9D9', // Neutral (1)
  '#D3C9D6', // Violet (5)
];

export const INNHOLDSELEMENT_THAT_HAS_ICONS = [
  'liste',
  'sporsmal',
  'kunnskapstekst',
  'kilde',
  'publikasjon',
  'dimensjon',
  'omrade',
];

export const INNHOLDSELEMENT_THAT_HAS_ICON_SPECIAL_CASE = ['kilde'];
