import React, { useState, useEffect } from 'react';
import { BlAccordion } from 'buflib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { sortObjects } from '../../store/helper-methods';
import useStore from '../../store/use-store';

import StoppPunkt from '../stopp-punkt/StoppPunkt';
import Sporsmal from '../innholdselementer/Sporsmal';

import './Oppgaver.scss';

/**
 * This component is used to display all the relevant oppgaver based
 * on what the user has selected.
 * @returns A list of oppgaver
 */
const Oppgaver = () => {
  const {
    selectedProsess,
    selectedOppgave,
    selectedSporsmal,
    getOppgaver,
    getSporsmalForOppgave,
    setSelectedOppgave,
    setSelectedSporsmal,
  } = useStore();
  const [oppgaver, setOppgaver] = useState([]);
  const [sporsmal, setSporsmal] = useState([]);

  useEffect(() => {
    if (selectedProsess > -1) {
      const prosesser = getOppgaver(selectedProsess).sort(sortObjects);
      setOppgaver(prosesser);
    }
    setSelectedOppgave(-1);
    setSelectedSporsmal(-1);
  }, [selectedProsess]);

  useEffect(() => {
    if (selectedOppgave > -1) {
      const fetchedSporsmal = getSporsmalForOppgave(selectedOppgave);
      setSporsmal(fetchedSporsmal);
    } else {
      setSporsmal([]);
    }
    setSelectedSporsmal(-1);
  }, [selectedOppgave]);

  const handleSetActiveOppgave = (oppgaveId) => {
    if (oppgaveId === selectedOppgave) {
      setSelectedOppgave(-1);
    } else {
      const oppgave = oppgaver.find((o) => o.id === oppgaveId);
      setSelectedOppgave(oppgave.id);
    }
  };

  const handleExpandSporsmal = (sporsmalId) => {
    if (sporsmalId === selectedSporsmal) {
      setSelectedSporsmal(-1);
    } else {
      setSelectedSporsmal(sporsmalId);
    }
  };

  const handleEventSetActiveOppgave = (e, oppgaveId) => {
    if (e.key === 'Enter') {
      handleSetActiveOppgave(oppgaveId);
    }
  };

  /**
   * Oppgave contains a prop 'oppgavetype' which defined what type of oppgave it is.
   * The two oppgavetyper are:
   *    1 = Standard
   *    2 = Stopp-punkt
   */

  if (selectedProsess !== -1 && oppgaver.length > 0) {
    return (
      <div className="columns">
        <div className="column">
          <ul>
            {oppgaver.map((o) => {
              // Check if oppgave is 'Stopp-punkt'
              // TODO: Remove the check on id when the JSON has been updated with proper 'oppgavetype' values
              if (o.oppgavetype === 2 || o.id === 99) {
                return (
                  <StoppPunkt
                    key={o.id}
                    oppgave={o}
                    isOpen={o.id === selectedOppgave}
                    handleExpandFunc={() => handleSetActiveOppgave(o.id)}
                  />
                );
              }
              return (
                <BlAccordion
                  headerElement={<h3 className="bl-size-3">{o.navn}</h3>}
                  isOpen={o.id === selectedOppgave}
                  onClick={() => handleSetActiveOppgave(o.id)}
                  key={o.id}
                  tabIndex={0}
                  onKeyPress={(e) => handleEventSetActiveOppgave(e, o.id)}
                >
                  <div className="bl-wysiwyg oppgave">
                    <p>{o.beskrivelse}</p>
                    {sporsmal.length > 0 &&
                      sporsmal.map((s) => (
                        <>
                          <Sporsmal
                            sporsmal={s}
                            handleExpand={(id) => handleExpandSporsmal(id)}
                            handleBoolskSporsmalToggleFunc={null}
                            checkedBoolskSporsmal={null}
                            expanded={s.id === selectedSporsmal}
                            showTekstreferanser={false}
                            icon={
                              <FontAwesomeIcon
                                color="#737373"
                                size="2x"
                                icon={['far', 'question']}
                              />
                            }
                          />
                        </>
                      ))}
                  </div>
                </BlAccordion>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  return <></>;
};

export default Oppgaver;
