import React from 'react';

import {
  Accordion,
  AccordionDetails,
  Button,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemAvatar,
  Card,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { PROPTYPE_SPORSMAL } from '../../store/element-proptypes';
import { SVAR_DATA_TYPE_STRINGS } from '../../store/constants';

import InnholdselementIkon from '../innholdselementikon/InnholdselementIkon';
import InnholdselementWarningIcon from '../innholdselement-warning-icon/InnholdselementWarningIcon';

// eslint-disable-next-line import/no-cycle
import InnholdselementListeWrapper from '../innholdselementer-liste-wrapper/InnholdselementerListeWrapper';

import useStore from '../../store/use-store';
import { DevAndQaOnly, DevOnly, IsProdEnvironment } from '../predicate-display/PredicateDisplay';

/**
 * This component renders out a Sporsmal.
 *  sporsmal                        = The innholdselement containing all object values.
 *  handleExpand                    = Function passed down from 'InnholdselementListe' to handle the expanding/collapsing of this component's content.
 *  expanded                        = State indicating if the component content is expanded or not.
 *  listLevel                       = An indication to the component on how deep in the 'tekstreferanser' hierarchy it is rendered in.
 *  inExpandMode                    = An indication to the component saying if it is shown in the 'ExpandedView' component or not.
 *  handleBoolskSporsmalToggleFunc  = Function passed down to handle if the checkbox for 'Boolsk' sporsmal is checked or not.
 *  checkedBoolskSporsmal           = An indication on the 'Boolsk' sporsmal checkbox state.
 *  icon                            = If a custom icon is required, it is sent in as a parameter.
 *  showTekstreferanser             = An indication if we want to display tekstreferanser or not.
 *  borderColor                     = The border color of the element
 *  backgroundColor                 = Background color for the element
 *  isDuplicate                     = Boolean saying if there is an element with the same ID as this one
 * @param {object} props Props containing all relevant props for sporsmal.
 * @returns Sporsmal-element.
 */

const Sporsmal = ({
  sporsmal,
  handleExpand,
  expanded,
  listLevel = 1,
  inExpandMode,
  handleBoolskSporsmalToggleFunc,
  checkedBoolskSporsmal,
  icon,
  showTekstreferanser = true,
  borderColor,
  backgroundColor,
  isDuplicate,
}) => {
  const { getTekstreferanser, getListElements } = useStore();
  const {
    id,
    svardatatype,
    navn,
    beskrivelse,
    tekstreferanser,
    svaralternativ,
  } = sporsmal;

  /**
   * Generates a tooltip for the innholdselement
   * @returns Tooltip element
   */
  const getTooptip = () => (
    <>
      <p>ID: {id}</p>
      <p>{SVAR_DATA_TYPE_STRINGS[svardatatype]}</p>
    </>
  );

  let listeelementer = [];
  if (svardatatype === 'ListeEnValg' || svardatatype === 'ListeFlervalg') {
    if (svaralternativ && svaralternativ.refType === 'liste') {
      listeelementer = getListElements(svaralternativ.refId);
    }
  }

  /**
   * There are five different types of svardatatype:
   *
   *    1= IngenSvar      = Ingen Svar / Reflekterende spørsmål
   *    2= Boolsk         = Boolsk / "Ja / Nei"
   *    3= Tekst          = Tekstlig svar
   *    4= ListeEnvalg    = En-valg fra liste
   *    5= ListeFlervalg  = Fler-valg fra liste
   *
   * 1 IngenSvar:     We only display 'navn' and 'beskrivelse'.
   *
   * 2 Boolsk:        We display the 'navn' with a checkbox next to it.
   *
   * 3 Tekst:         We only display 'navn' and 'beskrivelse'
   *
   * 4 ListeEnValg    We fetch the corresponding 'liste' and print out
   *                  all the listeelementer with a radio button next
   *                  to them. This way, the user can only check one
   *                  of the listeleementer.
   *
   * 5 ListeFlervalg  We fetch the corresponding 'liste' and print out
   *                  all the listeelementer with a checkbox next to
   *                  them. This way, the user can check one as many
   *                  of the listeleementer as they want.
   */

  if (svardatatype === 'Boolsk') {
    return (
      <Card
        style={{
          border:
            borderColor !== 'none' ? `2px solid ${borderColor}` : borderColor,
          background: 'rgba(0,0,0,0)',
          backgroundColor: 'rgba(0,0,0,0)',
        }}
      >
        <Accordion
          key={id}
          expanded={expanded}
          style={{
            background: inExpandMode ? backgroundColor : 'none',
            backgroundColor: inExpandMode ? backgroundColor : 'none',
          }}
        >
          <Button
            target="_blank"
            key={id}
            onClick={() => handleBoolskSporsmalToggleFunc(id)}
          >
            <ListItem>
              <ListItemAvatar>
                {/* <div> */}
                  {/* {icon || ( */}
                    <Tooltip
                      title={getTooptip()}
                      placement="left"
                      enterDelay={300}
                      leaveDelay={0}
                      disableFocusListener={IsProdEnvironment()}
                      disableHoverListener={IsProdEnvironment()}
                      disableTouchListener={IsProdEnvironment()}
                    >
                      <span>
                        <Checkbox
                          edge="start"
                          checked={checkedBoolskSporsmal}
                          tabIndex={-1}
                          disableRipple
                        />
                      </span>
                    </Tooltip>
                  {/* )} */}
                  {isDuplicate && (
                    <DevAndQaOnly>
                      <InnholdselementWarningIcon tooltipText="Dette elementet finnes flere steder i denne listen" />
                    </DevAndQaOnly>
                  )}
                {/* </div> */}
              </ListItemAvatar>
              <ListItemText secondary={beskrivelse}>{navn}</ListItemText>
            </ListItem>
          </Button>
        </Accordion>
      </Card>
    );
  }

  return (
    <>
      <Card
        style={{
          border:
            borderColor !== 'none' ? `2px solid ${borderColor}` : borderColor,
        }}
      >
        <Accordion key={id} expanded={expanded}>
          <Button target="_blank" key={id} onClick={() => handleExpand(id)}>
            <ListItem>
              <ListItemAvatar>
                {/* <div>
                  {icon || ( */}
                    <Tooltip
                      title={getTooptip()}
                      placement="left"
                      enterDelay={300}
                      leaveDelay={0}
                      disableFocusListener={IsProdEnvironment()}
                      disableHoverListener={IsProdEnvironment()}
                      disableTouchListener={IsProdEnvironment()}
                    >
                      <span>
                        <InnholdselementIkon
                          elementType="sporsmal"
                          elementObject={sporsmal}
                          color="#737373"
                        />
                      </span>
                    </Tooltip>
                  {/* )} */}
                  {isDuplicate && (
                    <DevAndQaOnly>
                      <InnholdselementWarningIcon tooltipText="Dette elementet finnes flere steder i denne listen" />
                    </DevAndQaOnly>
                  )}
                {/* </div> */}
              </ListItemAvatar>
              <ListItemText>{navn}</ListItemText>
            </ListItem>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <AccordionDetails>
            <p>{beskrivelse}</p>
            {listeelementer.length > 0 && (
              <h3>
                <b>Svaralternativer</b>
                <ul>
                  {listeelementer.map((le) => (
                    <li key={le.id}>{le.navn}</li>
                  ))}
                </ul>
              </h3>
            )}

            {showTekstreferanser &&
              expanded &&
              tekstreferanser &&
              tekstreferanser.length > 0 && (
                <InnholdselementListeWrapper
                  type="liste"
                  id={id}
                  inExpandMode={inExpandMode}
                  listLevel={listLevel}
                  innholdselementer={getTekstreferanser(tekstreferanser)}
                />
              )}
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
};

Sporsmal.propTypes = PROPTYPE_SPORSMAL;

export default Sporsmal;

/**
 * SPØRSMÅL SOM MÅ SJEKKES:
 *    - 39 (svardatatype 3, bare navn og beskrivelse) DONE
 *    - 51 (svardatatype 3, kilde i tekstreferanser) DONE
 *    - 96 (svardatatype 3, kunnskapstekst i tekstreferanser) DONE
 *    - 213 (svardatatype 1, bare navn og beskrivelse) DONE
 *    - 15 (svardatatype 5, kunnskapsmodell i tekstreferanser)
 *    - 999 (svardatatype 2, boolsk)
 *    - 111 (svardatatype 4, liste i svaralternativ)
 */
