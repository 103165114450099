import { useContext } from 'react';
import { Context } from './store';
import {
  generateRandomId,
  sortObjects,
  sortObjectsBasedOnRefType,
  sortObjectsBasedOnTeksttype,
} from './helper-methods';

const useStore = () => {
  const [state, setState] = useContext(Context);

  /**
   * @param {Object} parsedJson A parsed and validated json object to set in global state
   * @param {string} filename Name of file
   */
  function uploadJson(parsedJson, uploadedFilename) {
    setState({
      ...state,
      bfkJson: parsedJson,
      filename: uploadedFilename,
      selectedFase: -1,
      selectedProsess: -1,
      selectedOppgave: -1,
      selectedSporsmal: -1,
    });
  }

  /**
   * @param {string} file Name of file from azure storage container
   */
  function setStandardFile(name, file) {
    setState({
      ...state,
      filename: name,
      standardFile: file,
    });
  }

  /**
   * A function to get the file that is in use (either local og standard file)
   */
  function getActiveFile() {
    return state.bfkJson || state.standardFile;
  }

  function setIsLoadingStandardFile(isLoading) {
    setState({
      ...state,
      isLoadingStandardFile: isLoading,
    });
  }

  /**
   * Resetting the JSON file value for the state
   */
  function clearJson() {
    setState({
      ...state,
      bfkJson: null,
      filename: null,
      selectedFase: -1,
      selectedProsess: -1,
      selectedOppgave: -1,
      selectedSporsmal: -1,
    });
  }

  /**
   * This function determines which level the user has navigated to.
   *
   *    0 = Nothing is selected
   *    1 = Fase
   *    2 = Prosess
   *    3 = Oppgave
   *    4 = Sporsmal
   */
  function getCurrentUserLevel() {
    if (state.selectedSporsmal > -1) {
      return 4;
    }
    if (state.selectedOppgave > -1) {
      return 3;
    }
    if (state.selectedProsess > -1) {
      return 2;
    }
    if (state.selectedFase > -1) {
      return 1;
    }
    return 0;
  }

  /**
   * @param {object[]} tekstreferanser List of tekstreferanser that is to be fetched
   * @returns Objects with all props belonging to the tekstreferanser passed to the function
   */
  function getTekstreferanser(tekstreferanser) {
    // eslint-disable-next-line prefer-const
    let relevantElementer = [];
    relevantElementer.push(
      ...tekstreferanser
        .map((i) => {
          // Get info about tekstreferanse
          const { refId, refType } = i;
          // Find innholdselement based fetched information
          let innholdselement = getActiveFile()[refType].find(
            (e) => e.id === refId,
          );
          // For easier access to refType, we add it in the object as a value
          if (innholdselement) {
            innholdselement = {
              ...innholdselement,
              refType,
            };
          }
          return innholdselement || null;
        })
        .filter((p) => p !== null),
    );
    return relevantElementer;
  }

  /**
   * @param {object} tekstreferanse Reference information about a element
   * @returns A object containing the props for the element asked for in 'tekstreferanse'
   */
  function getTekstreferanse(tekstreferanse) {
    const { refId, refType } = tekstreferanse;
    const innholdselement = getActiveFile()[refType].find(
      (e) => e.id === refId,
    );
    return innholdselement || null;
  }

  /**
   * @param {string} id Id of a kunnskapsmodell
   * @returns A object with props for kunnskapsmodell.
   */
  function getKunnskapsmodell(id) {
    if (id) {
      const kunnskapsmodell = getActiveFile().kunnskapsmodell.find(
        (i) => i.id === id,
      );
      return kunnskapsmodell;
    }
    return getActiveFile().kunnskapsmodell[0] || null;
  }

  function getDimensjon(id) {
    const fase = getActiveFile().dimensjon.find((i) => i.id === id);
    return fase || null;
  }

  function getFase(id) {
    const fase = getActiveFile().fase.find((i) => i.id === id);
    return fase || null;
  }

  function getProsess(id) {
    const prosess = getActiveFile().prosess.find((i) => i.id === id);
    return prosess || null;
  }

  function getOppgave(id) {
    const oppgave = getActiveFile().oppgave.find((i) => i.id === id);
    return oppgave || null;
  }

  function getSporsmal(id) {
    const sporsmal = getActiveFile().sporsmal.find((i) => i.id === id);
    return sporsmal || null;
  }

  function getListe(id) {
    const liste = getActiveFile().liste.find((i) => i.id === id);
    return liste || null;
  }

  function getDimensjoner(id) {
    if (id) {
      return getTekstreferanser(getKunnskapsmodell(id).dimensjoner);
    }
    return getActiveFile().dimensjon;
  }

  function getOmrader(id) {
    if (id) {
      return getTekstreferanser(getDimensjon(id).omrader);
    }
    return getActiveFile().omrade;
  }

  function getFaser(id) {
    if (id) {
      return getActiveFile().fase.filter((i) => i.id === id);
    }
    return getActiveFile().fase;
  }

  function getProsesser(faseId) {
    if (faseId) {
      const fase = getFase(faseId);
      if (fase) {
        const relevantProsesserId = fase.prosesser.map((p) => p.refId);
        const relevantProsesser = relevantProsesserId
          .map((pId) => {
            const prosess = getActiveFile().prosess.find((p) => p.id === pId);
            return prosess || null;
          })
          .filter((p) => p !== null);
        return relevantProsesser;
      }
      return [];
    }
    return getActiveFile().prosess;
  }

  function getOppgaver(prosessId) {
    if (prosessId) {
      const prosess = getProsess(prosessId);
      if (prosess) {
        const relevantOppgaverId = prosess.oppgaver.map((o) => o.refId);
        const relevantOppgaver = relevantOppgaverId
          .map((oId) => {
            const foundProsess = getActiveFile().oppgave.find(
              (o) => o.id === oId,
            );
            return foundProsess || null;
          })
          .filter((p) => p !== null);
        return relevantOppgaver;
      }
      return [];
    }
    return getActiveFile().oppgave;
  }

  function getSporsmalForOppgave(oppgaveId) {
    if (oppgaveId) {
      const oppgave = getOppgave(oppgaveId);
      if (oppgave) {
        const relevantSporsmalId = oppgave.sporsmal.map((s) => s.refId);
        const relevantSporsmal = relevantSporsmalId
          .map((sId) => {
            const sporsmal = getActiveFile().sporsmal.find((s) => s.id === sId);
            return sporsmal || null;
          })
          .filter((p) => p !== null);
        return relevantSporsmal;
      }
      return [];
    }
    return [];
  }

  function getListElements(lId) {
    if (lId) {
      const liste = getListe(lId);
      if (liste) {
        const { listeelementer } = liste;
        if (listeelementer && listeelementer.length > 0) {
          const relevantListeelementerId = listeelementer.map((s) => s.refId);
          const relevantListeelementer = relevantListeelementerId
            .map((eId) => {
              const listeelement = getActiveFile().listeelement.find(
                (le) => le.id === eId,
              );
              return listeelement || null;
            })
            .filter((p) => p !== null);
          return relevantListeelementer;
        }
      }
    }
    return null;
  }

  function getGlobaleKunnskapstekster() {
    // eslint-disable-next-line prefer-const
    let relevantElementer = [];

    // Fetch relevant innholdselementer from Fase
    if (state.selectedFase > -1) {
      const fase = getFase(state.selectedFase);
      if (fase) {
        relevantElementer.push(...getTekstreferanser(fase.tekstreferanser));
      }
    }

    // Fetch relevant innholdselementer from Prosess
    if (state.selectedProsess > -1) {
      const prosess = getProsess(state.selectedProsess);
      if (prosess) {
        relevantElementer.push(...getTekstreferanser(prosess.tekstreferanser));
      }
    }
    return relevantElementer.sort(sortObjects).sort(sortObjectsBasedOnRefType).sort(sortObjectsBasedOnTeksttype);
  }

  function getKunnskapOgVeiledning(level) {
    if (level === 4) {
      return getTekstreferanser(
        getSporsmal(state.selectedSporsmal).tekstreferanser,
      ).sort(sortObjectsBasedOnRefType).sort(sortObjectsBasedOnTeksttype);
    }
    if (level === 3) {
      return getTekstreferanser(
        getOppgave(state.selectedOppgave).tekstreferanser,
      ).sort(sortObjectsBasedOnRefType).sort(sortObjectsBasedOnTeksttype);
    }
    if (level === 2) {
      return getTekstreferanser(
        getProsess(state.selectedProsess).tekstreferanser,
      ).sort(sortObjectsBasedOnRefType).sort(sortObjectsBasedOnTeksttype);
    }
    if (level === 1) {
      return getTekstreferanser(
        getFase(state.selectedFase).tekstreferanser,
      ).sort(sortObjectsBasedOnRefType).sort(sortObjectsBasedOnTeksttype);
    }
    return [];
  }

  function setSelectedFase(id) {
    setState({
      ...state,
      selectedFase: id,
      selectedProsess: -1,
      selectedOppgave: -1,
    });
  }

  function setSelectedProsess(id) {
    setState({
      ...state,
      selectedProsess: id,
      selectedOppgave: -1,
    });
  }

  function setSelectedOppgave(id) {
    setState({
      ...state,
      selectedOppgave: id,
    });
  }

  function setSelectedSporsmal(id) {
    setState({
      ...state,
      selectedSporsmal: id,
    });
  }

  function upsertErrors(errorTitle, errorMessage) {
    const newError = {
      title: errorTitle,
      msg: errorMessage,
      id: generateRandomId(),
    };
    const updatedErrors = [...state.errors, newError];
    setState({
      ...state,
      errors: updatedErrors,
    });
  }

  function deleteError(errorId) {
    const updatedErrors = state.errors.filter((e) => e.id !== errorId);
    setState({
      ...state,
      errors: updatedErrors,
    });
  }

  function setEnvironment(env) {
    setState({
      ...state,
      environment: env
    })
  }

  function getEnvironment() {
    return state.environment
  }

  function setVersions(vers) {
    
    setState({
      ...state,
      versions: vers
    })
  }

  function getVersions() {
    return state.versions;
  }

  function setSelectedVersion(v) {
    setState({
      ...state,
      selectedVersion: v
    })
  }

  function getSelectedVersion() {
    state.selectedVersion
  }

  return {
    bfkJson: state.bfkJson,
    standardFile: state.standardFile,
    isLoadingStandardFile: state.isLoadingStandardFile,
    activeFile: getActiveFile(),
    filename: state.filename,
    hasUploaded: getActiveFile() !== null,
    errors: state.errors,
    selectedFase: state.selectedFase,
    selectedProsess: state.selectedProsess,
    selectedOppgave: state.selectedOppgave,
    selectedSporsmal: state.selectedSporsmal,
    currentUserLevel: getCurrentUserLevel(),
    environment: state.getEnvironment,
    clearJson,
    uploadJson,
    setStandardFile,
    setIsLoadingStandardFile,
    getKunnskapsmodell,
    getDimensjoner,
    getOmrader,
    getFaser,
    getProsesser,
    getOppgaver,
    getSporsmalForOppgave,
    getListElements,
    getTekstreferanser,
    getTekstreferanse,
    getGlobaleKunnskapstekster,
    getKunnskapOgVeiledning,
    setSelectedFase,
    setSelectedProsess,
    setSelectedOppgave,
    setSelectedSporsmal,
    upsertErrors,
    deleteError,
    setEnvironment,
    getEnvironment,
    setVersions,
    getVersions,
    setSelectedVersion,
    getSelectedVersion
  };
};

export default useStore;
