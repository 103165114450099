import React, { useMemo, useState, useEffect } from "react";
import { fetchEnvSettings } from '../store/helper-methods';
import { ApolloProvider } from '@apollo/client';

import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
  } from '@apollo/client';

const cache = new InMemoryCache()

const createClient = (
  uri,
) =>
  new ApolloClient({
    cache,
    link:
      new HttpLink({
        uri,
      }),
  });

export const ApolloWrapper = ({ children }) => {
  const [apiUrl, setApiUrl] = useState('')
  useEffect(async () => {
    const fetchedEnvSettings = await fetchEnvSettings();
    //setEnvironment(fetchedEnvSettings.API_URL)
    console.log(fetchedEnvSettings.API_URL);
    setApiUrl(`https://${fetchedEnvSettings.API_URL}/bufdir/bfk/api`);

  }, [])
  // Create and memoize the Apollo client once the environment is loaded
  const client = useMemo(() => {
    if (apiUrl) {
      return createClient(apiUrl);
    }
    return undefined;
  }, [apiUrl]);

  if (!client) {
    return <></>;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};