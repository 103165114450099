import React from 'react';

import {
  Accordion,
  AccordionDetails,
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Card,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { PROPTYPE_LISTE } from '../../store/element-proptypes';

import InnholdselementIkon from '../innholdselementikon/InnholdselementIkon';
import InnholdselementWarningIcon from '../innholdselement-warning-icon/InnholdselementWarningIcon';

// eslint-disable-next-line import/no-cycle
import InnholdselementListeWrapper from '../innholdselementer-liste-wrapper/InnholdselementerListeWrapper';

import useStore from '../../store/use-store';
import { DevAndQaOnly, DevOnly, IsProdEnvironment } from '../predicate-display/PredicateDisplay';

/**
 * This is a component displaying a innholdselement of type Liste.
 *  liste             = Props to be displayed
 *  handleExpand      = Handle function to expand the Liste
 *  expanded          = Boolean saying if the innholdselement is expanded
 *  listLevel         = How many levels down in the reference hierarchy the innholdselement is displayed at
 *  inExpandMode      = Tells the innholdselement if it is shown in an expanded window
 *  borderColor       = Border color of the element (for list level use)
 *  backgroundColor   = Background color for the element
 *  isDuplicate       = Boolean saying if there is an element with the same ID as this one
 * @param {Props} param0 Props to display a Liste
 * @returns A innholdselement of type Liste
 */
const Liste = ({
  liste,
  handleExpand,
  expanded,
  inExpandMode,
  listLevel,
  borderColor,
  backgroundColor,
  isDuplicate,
}) => {
  const { getListElements, getTekstreferanser } = useStore();
  const { id, navn, listeelementer, tekstreferanser } = liste;
  const relevantListeelementer = getListElements(id);
  const getTooptip = () => (
    <>
      <p>ID: {id}</p>
      <p>Liste</p>
    </>
  );

  return (
    <>
      <Card
        style={{
          border:
            borderColor !== 'none' ? `2px solid ${borderColor}` : borderColor,
          background: 'rgba(0,0,0,0)',
          backgroundColor: 'rgba(0,0,0,0)',
        }}
      >
        <Accordion
          key={id}
          expanded={expanded}
          onClick={() => handleExpand(id)}
          style={{
            background: inExpandMode ? backgroundColor : 'none',
            backgroundColor: inExpandMode ? backgroundColor : 'none',
          }}
        >
          <Button target="_blank" key={id}>
            <ListItem>
              <Tooltip
                title={getTooptip()}
                placement="left"
                enterDelay={300}
                leaveDelay={0}
                disableFocusListener={IsProdEnvironment()}
                disableHoverListener={IsProdEnvironment()}
                disableTouchListener={IsProdEnvironment()}
              >
                <ListItemAvatar>
                  <div>
                    <InnholdselementIkon
                      elementType="liste"
                      elementObject={liste}
                      color="#737373"
                    />
                    {isDuplicate && (
                    <DevAndQaOnly>
                      <InnholdselementWarningIcon tooltipText="Dette elementet finnes flere steder i denne listen" />
                    </DevAndQaOnly>
                    )}
                  </div>
                </ListItemAvatar>
              </Tooltip>
              <ListItemText>
                {navn} ({listeelementer.length})
              </ListItemText>
            </ListItem>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <AccordionDetails>
            {relevantListeelementer.length > 0 ? (
              <ul>
                {relevantListeelementer.map((le) => (
                  <li key={le.id}>{le.navn}</li>
                ))}
              </ul>
            ) : (
              <p>Listen er dessverre tom.</p>
            )}
            {expanded && tekstreferanser && tekstreferanser.length > 0 && (
              <InnholdselementListeWrapper
                type="liste"
                id={id}
                inExpandMode={inExpandMode}
                listLevel={listLevel}
                innholdselementer={getTekstreferanser(tekstreferanser)}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
};

Liste.propTypes = PROPTYPE_LISTE;

export default Liste;
