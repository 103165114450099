import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import useStore from '../../store/use-store';

import ExtendedView from '../extended-view/ExtendedView';
import InnholdselementerListe from '../innholdselementer-liste/InnholdselementerListe';

/**
 * A component containing all innholdselementer related to the current
 * innholdselement selected by the user. It is not like Globale Kunnskapstekster
 * where it is a combined list for both Fase and Prosess, but it is ONLY
 * tekstreferanser based on that one element the user has selected.
 * This list will react on levels selected by the user all the way down to Oppgave-level.
 * @param {object} props Props used to display KunnskapOgVeiledning panel
 * @returns Component containing innholdselementer
 */
const KunnskapOgVeiledning = ({ icon, title }) => {
  const {
    selectedFase,
    selectedProsess,
    selectedOppgave,
    selectedSporsmal,
    currentUserLevel,
    getKunnskapOgVeiledning,
  } = useStore();
  const [content, setContent] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchedInnholdselementer = getKunnskapOgVeiledning(currentUserLevel);
    setContent(fetchedInnholdselementer);
  }, [
    currentUserLevel,
    selectedFase,
    selectedProsess,
    selectedOppgave,
    selectedSporsmal,
  ]);

  const handleToggleExtendedView = () => {
    setIsExpanded(!isExpanded);
  };

  if (isExpanded) {
    return (
      <ExtendedView
        title={title}
        icon={icon}
        handleCloseFunc={() => handleToggleExtendedView()}
      >
        <InnholdselementerListe innholdselementer={content} inExpandMode />
      </ExtendedView>
    );
  }
  return (
    <div className="innholdselementliste-container">
      {/* <Tooltip
        title="Utvidet listevisning"
        placement="top"
        enterDelay={300}
        leaveDelay={0}
      > */}
        <div className="innholdselementliste-container__expandIcon">
          <FontAwesomeIcon
            onClick={() => handleToggleExtendedView()}
            color="#737373"
            icon={['far', 'expand']}
            size="2x"
          />
        </div>
      {/* </Tooltip> */}
      <InnholdselementerListe innholdselementer={content} />
    </div>
  );
};

KunnskapOgVeiledning.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default KunnskapOgVeiledning;
