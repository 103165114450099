import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';

import './ExtendedView.scss';

/**
 * To display content (kunnskapselementer panel) in a enlarged view,
 * we send the react elements into this component (children props)
 * and display them in this modal view.
 *  children          = Elements to be displayed
 *  icon              = The icon to be displayed in the top corner
 *  title             = The title of the module
 *  handleCloseFunc   = The close function for the module
 * @param {object} param0 Props used to display the extended view
 * @returns A component that displays the given children in a module view
 */
const ExtendedView = ({ children, icon, title, handleCloseFunc }) => (
  <Modal
    open
    onClose={() => handleCloseFunc()}
    aria-labelledby="modal-for-innholdselement"
    aria-describedby="simple-modal-for-innholdselement-list"
  >
    <div className="extended-view">
      <div className="extended-view__header">
        <div className="extended-view__header--title">
          {icon}
          <span>{title}</span>
        </div>
        <Tooltip
          title="Lukk utvidet vindu"
          placement="top"
          enterDelay={300}
          leaveDelay={0}
        >
          <ClearIcon
            fontSize="large"
            onClick={() => handleCloseFunc()}
            className="extended-view__header--closeIcon"
          />
        </Tooltip>
      </div>
      <div className="extended-view__content">{children}</div>
    </div>
  </Modal>
);

ExtendedView.propTypes = {
  /**
   * Content of the extended view
   * */
  children: PropTypes.node.isRequired,
  /**
   * Icon for the modal
   * */
  icon: PropTypes.node.isRequired,
  /**
   * Title/Header for the modal
   * */
  title: PropTypes.string.isRequired,
  /**
   * Function to close the modal
   * */
  handleCloseFunc: PropTypes.func.isRequired,
};

export default ExtendedView;
