import useStore from '../../store/use-store'
import React from 'react'

const PredicateDisplay = (props) => {
    return(
        <div>
            { props.pred && props.children}
        </div>
    )
}

export const DevOnly = (props) => {
    const { getEnvironment } = useStore();
    return(
        <PredicateDisplay pred={
            (getEnvironment() === 'env-bfkvv-dev')}>
            { props.children }
        </PredicateDisplay>
    )
}

export const DevAndQaOnly = (props) => {
    const { getEnvironment } = useStore();
    return(
        <PredicateDisplay pred={
            (getEnvironment() === 'env-bfkvv-dev' || 
            getEnvironment() === 'env-bfkvv-qa')}>
            { props.children }
        </PredicateDisplay>
    )
}

export const IsProdEnvironment = () => {
    const { getEnvironment } = useStore();
    return (
        getEnvironment() === 'env-bfkvv-prod' || 
        getEnvironment() === 'env-bfkvv-qa'
    )
}