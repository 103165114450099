import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PROPTYPE_INNHOLDSELEMENT_ICON } from '../../store/element-proptypes';
import {
  INNHOLDSELEMENT_THAT_HAS_ICONS,
  INNHOLDSELEMENT_THAT_HAS_ICON_SPECIAL_CASE,
} from '../../store/constants';

/**
 * This component is used to look for which icon to use for Innholdselement based on
 * checks and special cases if there are any.
 * @param {Object} param0 Props to display InnholdselementIkon
 * @returns Icon for innholdselement
 */
const InnholdselementIkon = ({
  elementType,
  elementObject,
  color,
  size = '2x',
}) => {
  /**
   * Based on the parameter, this function will determine if the type has icon or not.
   * This could be handy since some types does not have icons.
   * @param {string} type Type of innholdselement
   * @returns Boolean (true if type has icon, false if not).
   */
  const innholdselementHasIcon = (type) =>
    INNHOLDSELEMENT_THAT_HAS_ICONS.indexOf(type) > -1;

  /**
   * Some innholdselementer has a special case for icons. 'Kilde' has a case
   * where if 'teksttype' is 5, the icon is not a bookmark but '§'.
   * @param {string} type Type of innholdselement
   * @returns Boolean (true if type has special case for icon, false if not).
   */
  const innholdselementHasSpecialCase = (type) =>
    INNHOLDSELEMENT_THAT_HAS_ICON_SPECIAL_CASE.indexOf(type) > -1;

  /**
   * Based on the type, this funciton will return an array on the correct format
   * for FontAwesome to use to get the correct icon for an innholdselement.
   * @param {string} type Type of innholdselement
   * @returns An array on the correct format for FontAwesome to use to get the right icon.
   */
  const getInnholdselementIconString = (type) => {
    switch (type) {
      case 'liste':
        return ['far', 'list'];
      case 'sporsmal':
        return ['far', 'question'];
      case 'kunnskapstekst':
        return ['far', 'book'];
      case 'kilde':
        return ['far', 'bookmark'];
      case 'publikasjon':
        return ['far', 'file-powerpoint'];
      case 'dimensjon':
        return ['far', 'triangle'];
      case 'omrade':
        return ['fal', 'map-signs'];
      // TODO: Get a fallback icon
      default:
        return ['fad', 'archive'];
    }
  };

  /**
   * Based on the type, this funciton will return an array on the correct format
   * for FontAwesome to use to get the correct icon for an innholdselement.
   * @param {string} type Type of innholdselement
   * @returns An array on the correct format for FontAwesome to use to get the right icon.
   */
  const getInnholdselementIcon = (type) => (
    <FontAwesomeIcon
      color={color}
      size={size}
      icon={getInnholdselementIconString(type)}
      style={{ margin: '8px' }}
    />
  );

  /**
   * This function gets the special case icon. If the special case is not
   * resolved in the code (it does not fulfil any of the if-statements), it
   * will fall back on the standard top-level icons. We know that this type
   * always will have a top-level icon as fallback because is passed in
   * 'innholdselementHasIcon' in the first if-statement.
   * @param {string} type Type of innholdselement
   * @param {object} element Innholdselement-object
   * @returns String or FontAwesome icon element
   */
  const getInnholdselementSpecialCaseIcon = (type, element) => {
    if (type === 'kilde' && element.teksttype && element.teksttype === 'JuridiskKilde') {
      return (
        <span style={{ fontSize: '2em', margin: '8px', color: '#737373' }}>
          §
        </span>
      );
    }
    return getInnholdselementIcon(type);
  };

  /**
   * To return the corresponding icon, we first check if
   * the elementtype has a predefined icon. If not we return 'null'.
   * If it does, we first check if it has a special case and
   * return the special case. If it does not have a special case,
   * we just return the predefined top-level icon.
   */
  if (innholdselementHasIcon(elementType)) {
    if (innholdselementHasSpecialCase(elementType)) {
      return getInnholdselementSpecialCaseIcon(elementType, elementObject);
    }
    return getInnholdselementIcon(elementType);
  }
  return null;
};

InnholdselementIkon.propTypes = PROPTYPE_INNHOLDSELEMENT_ICON;

export default InnholdselementIkon;
