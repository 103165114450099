import React, { useState } from 'react';

import { PROPTYPE_OMRADER } from '../../store/element-proptypes';

import Omrade from '../innholdselementer/Omrade';

import './Omrader.scss';

/**
 * This component renders out a list of omrader.
 *  omrader = The omrader to be displayed
 *  color   = The color of the omrade passed down from Dimensjon
 * @param {Object} param0 Props to display a list of omrader
 * @returns A list of multiple Omrade
 */
const Omrader = ({ omrader, color }) => {
  const [expandedOmrade, setExpandedOmrade] = useState(false);

  /**
   * This function handles the expand/collapse of all omrader.
   * Only one can be expanded at a time.
   * @param {string} id id of omrade
   */
  const handleExpand = (id) => {
    setExpandedOmrade(id === expandedOmrade ? false : id);
  };

  return (
    <>
      <ul className="omraderliste">
        {omrader &&
          omrader.length > 0 &&
          omrader.map((d) => (
            <Omrade
              key={d.id}
              omrade={d}
              isExpanded={d.id === expandedOmrade}
              handleExpandFunc={(id) => handleExpand(id)}
              color={color}
            />
          ))}
      </ul>
    </>
  );
};

Omrader.propTypes = PROPTYPE_OMRADER;

export default Omrader;
