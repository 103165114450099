import React from 'react';
import {
  Accordion,
  AccordionDetails,
  Typography,
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Card,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { getTekstTypeString } from '../../store/helper-methods';
import { PROPTYPE_KUNNSKAPSTEKST } from '../../store/element-proptypes';
import InnholdselementIkon from '../innholdselementikon/InnholdselementIkon';
import InnholdselementWarningIcon from '../innholdselement-warning-icon/InnholdselementWarningIcon';
import sanitizeHtml from "../../util/sanitize-html";
// eslint-disable-next-line import/no-cycle
import InnholdselementListeWrapper from '../innholdselementer-liste-wrapper/InnholdselementerListeWrapper';
import useStore from '../../store/use-store';
import { DevAndQaOnly, DevOnly, IsProdEnvironment} from '../predicate-display/PredicateDisplay';

/**
 * This is a component displaying a innholdselement of type Kunnskapstekst.
 *  kunnskapstekst    = Props to be displayed
 *  handleExpand      = Handle function to expand the Kunnskapstekst
 *  expanded          = Boolean saying if the innholdselement is expanded
 *  listLevel         = How many levels down in the reference hierarchy the innholdselement is displayed at
 *  inExpandMode      = Tells the innholdselement if it is shown in an expanded window
 *  borderColor       = Border color of the element (for list level use)
 *  backgroundColor   = Background color for the element
 *  isDuplicate       = Boolean saying if there is an element with the same ID as this one
 * @param {Props} param0 Props to display a Kunnskapstekst
 * @returns A innholdselement of type Kunnskapstekst
 */
const Kunnskapstekst = ({
  kunnskapstekst,
  handleExpand,
  expanded,
  listLevel = 1,
  inExpandMode,
  borderColor,
  backgroundColor,
  isDuplicate,
}) => {
  const { getTekstreferanser } = useStore();
  const { id, navn, beskrivelse, tekst, teksttype, tekstreferanser } =
    kunnskapstekst;

  const getTooptip = () => (
    <>
      <p>ID: {id}</p>
      <p>{getTekstTypeString(teksttype)}</p>
    </>
  );

  return (
    <>
      <Card
        style={{
          border:
            borderColor !== 'none' ? `2px solid ${borderColor}` : borderColor,
          background: 'rgba(0,0,0,0)',
          backgroundColor: 'rgba(0,0,0,0)',
        }}
      >
        <Accordion
          key={id}
          expanded={expanded}
          style={{
            background: inExpandMode ? backgroundColor : 'none',
            backgroundColor: inExpandMode ? backgroundColor : 'none',
          }}
        >
          <Button target="_blank" key={id} onClick={() => handleExpand(id)}>
            <ListItem>
              <ListItemAvatar>
                {/* <div> */}
                  <Tooltip
                    title={getTooptip()}
                    placement="left"
                    enterDelay={300}
                    leaveDelay={0}
                    disableFocusListener={IsProdEnvironment()}
                    disableHoverListener={IsProdEnvironment()}
                    disableTouchListener={IsProdEnvironment()}
                  >
                    <span>
                      <InnholdselementIkon
                        elementType="kunnskapstekst"
                        elementObject={kunnskapstekst}
                        color="#737373"
                      />
                    </span>
                  </Tooltip>
                  {isDuplicate && (
                    <DevAndQaOnly>
                      <InnholdselementWarningIcon tooltipText="Dette elementet finnes flere steder i denne listen" />
                    </DevAndQaOnly>
                  )}
                {/* </div> */}
              </ListItemAvatar>
              <ListItemText secondary={beskrivelse}>{navn}</ListItemText>
            </ListItem>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <AccordionDetails>
            <Typography dangerouslySetInnerHTML={sanitizeHtml(tekst)} />
            {expanded && tekstreferanser && tekstreferanser.length > 0 && (
              <InnholdselementListeWrapper
                type="liste"
                id={id}
                inExpandMode={inExpandMode}
                listLevel={listLevel}
                innholdselementer={getTekstreferanser(tekstreferanser)}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
};

Kunnskapstekst.propTypes = PROPTYPE_KUNNSKAPSTEKST;

export default Kunnskapstekst;
