import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepConnector
} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  sortObjects,
  getObjectValues,
  getProsessIcon,
} from '../../store/helper-methods';
import useStore from '../../store/use-store';

import './Tidslinje.scss';

/**
 * Style for the connector between each fase.
 */
const BufdirStepConnectorFaser = withStyles({
  alternativeLabel: {
    top: 105,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  line: {
    borderColor: '#e7eced',
    borderTopWidth: 8,
    borderRadius: 1,
  },
})(StepConnector);

/**
 * Style for the connector between each prosess.
 */
const BufdirStepConnectorProsesser = withStyles({
  alternativeLabel: {
    top: 21,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  line: {
    borderColor: '#e7eced',
    borderTopWidth: 8,
    borderRadius: 1,
  },
})(StepConnector);

/**
 * This is a timeline that displays the chronological order of Fase and Prosess.
 * @returns A tidslinje containing all faser and prosesser
 */
const Tidslinje = () => {
  const {
    activeFile,
    selectedFase,
    selectedProsess,
    getFaser,
    setSelectedFase,
    getProsesser,
    setSelectedProsess,
  } = useStore();
  const [faser, setFaser] = useState([]);
  const [prosesser, setProsesser] = useState([]);

  /**
   * The function is used to fetch all faser from the JSON file.
   * @returns All faser from the JSON file.
   */
  const getSteps = () => {
    const relevanteFaser = getFaser(null)
      .map(getObjectValues)
      .sort(sortObjects);
    if (relevanteFaser.length > 0) {
      return relevanteFaser;
    }
    return [];
  };

  /**
   * The first time the component is instantiated,
   * we fetch all faser and set them in the useState.
   */
  useEffect(() => {
    setFaser(getSteps());
  }, []);

  /**
   * If a new json file is uploaded, we reset all values.
   */
  useEffect(() => {
    setSelectedFase(-1);
    setSelectedProsess(-1);
    setFaser(getSteps());
  }, [activeFile]);

  /**
   * When a fase is selected (not -1), we fetch the relevant
   * prosesser from the JSON file and add them to the useState
   * for prosesses.
   *
   * If the selected fase is -1, we reset the selected prosess
   * (set it to -1) and empty the list of relevant prosesser.
   */
  useEffect(() => {
    if (selectedFase > -1) {
      const relevantProsesser = getProsesser(selectedFase)
        .map(getObjectValues)
        .sort(sortObjects);
      if (relevantProsesser.length > 0) {
        setSelectedProsess(-1);
        setProsesser(relevantProsesser);
      } else {
        setSelectedProsess(-1);
        setProsesser([]);
      }
    }
  }, [selectedFase]);

  /**
   * When a user clicks a fase, the selectedFase value in store is updated
   * @param {string} faseId The fase selected by the user
   * @returns void
   */
  const handleSetActiveFase = (faseId) => {
    setSelectedFase(faseId);
  };

  /**
   * This is the event handler for when a user clicks "Enter" when focusing on a fase
   * @param {any} e Event triggered by user
   * @param {int} faseId Fase id
   */
  const handleEventSetActiveFase = (e, faseId) => {
    if (e.key === 'Enter') {
      handleSetActiveFase(faseId);
    }
  };

  /**
   * When a user clicks a prosess, the selectedProsess value in store is updated
   * @param {string} prosessId The prosess selected by the user
   * @returns void
   */
  const handleSetActiveProsess = (prosessId) => {
    if(selectedProsess === prosessId){
      setSelectedProsess(-1)
    } else {
      setSelectedProsess(prosessId);
    }
  };

  /**
   *
   * @param {string} id The id of a prosess which is used to find the correct icon.
   * @returns A div-element which contains icons coorelating to all relevant prosesser
   * in the relevant fase.
   */
  const faseIcons = (id) => {
    const prosesserForFase = getProsesser(id)
      .map(getObjectValues)
      .sort(sortObjects);
    return (
      <div className={`step__iconsContainer ${prosesserForFase.length > 4 && "step__iconsContainerMargin"}`}>
        {/* If there are relevant prosesser for the relevant fase,
            we loop through and "stack" the relevant icons behind eachother */}
        {prosesserForFase.length > 0 ? (
          prosesserForFase.map((p, i) => (
            <div
              key={p.id}
              className="step__faseIconReplacement"
              style={{right: `${-20 * i}px`, zIndex: 10 * i}}
            >
              {/* Icons aren't to be used for now, but will be used in the future */}
              {/* <FontAwesomeIcon */}
              {/*  color="white" */}
              {/*  key={p.id} */}
              {/*  icon={getProsessIcon(p.id)} */}
              {/* /> */}
            </div>
          ))
        ) : (
          <div className="step__icon">
            {/* If no prosesser exists for the currect fase, we only print an empty icon */}
            <FontAwesomeIcon color="white" icon={getProsessIcon(-1)}/>
          </div>
        )}
      </div>
    );
  };

  /**
   * This function returns the correct icon based in its state (active or not).
   * @param {string} prosessId ID of a prosess
   * @param {boolean} isActive State telling us if the current prosess is the same prosess as selected by the user
   * @returns The correct icon for the prosess needed.
   */
  const prosessIcon = (prosessId, isActive, hasNegativeSort) => {
    let color = 'white';
    if (hasNegativeSort) {
      color = '#79262e';
    } else if (isActive) {
      color = '#737373';
    }
    return (
      <>
        {
          prosessId === 120 ?
            <div className={`step__icon ${isActive && 'step__icon--active'}`}>
              <FontAwesomeIcon color={color} icon={getProsessIcon(prosessId)}/>
            </div>
            :
            <div
              className={`step__prosessIconReplacement ${isActive ? 'step__prosessIconReplacementSelected' : 'step__prosessIconReplacementUnselected'}`}/>
        }
      </>
    );
  };

  return (
    <>
      <div id="faser" className="columns">
        {/* If no fase is selected by the user, we want to print out all of them in a 'folded' manner. */}
        {selectedFase === -1 && (
          <div className="column column--faseIsNotSelected column--notTheActiveFase">

            <Stepper
              className="stepper"
              alternativeLabel
              nonLinear
              activeStep={selectedFase}
              connector={<BufdirStepConnectorFaser/>}
            >
              {faser.map((fase) => (
                <Step
                  className="step"
                  key={fase.id}
                  onClick={() => handleSetActiveFase(fase.id)}
                  tabIndex={0}
                  onKeyPress={(e) => handleEventSetActiveFase(e, fase.id)}
                >
                  <StepLabel className="step__title step__title--doNotDisplayIcon">
                    <h3>{fase.navn}</h3>
                  </StepLabel>
                  <StepLabel StepIconComponent={() => faseIcons(fase.id)}/>
                </Step>
              ))}
            </Stepper>
          </div>
        )}

        {/*
          If a fase is selected by the user, we want to print out the
          selected fase in an expanded view and the other faser in a 'folded' view.
          This way it is easy for the user to navigate between both prosesser and faner.
      */}
        {selectedFase > -1 &&
        prosesser.length > 0 &&
        faser.map((fase) => {
          /**
           * If the currenct fase is the active fase (the fase selected by the user).
           */
          if (fase.id === selectedFase) {
            return (
              <div
                key={fase.id}
                className="column column--faseIsSelected column--theActiveFase"
              >
                <StepLabel className="step__title step__title--doNotDisplayIcon">
                  <h3>{fase.navn}</h3>
                </StepLabel>
                <Stepper
                  className="stepper"
                  alternativeLabel
                  nonLinear
                  activeStep={selectedProsess}
                  connector={<BufdirStepConnectorProsesser/>}
                >
                  {/*
                    First we filter out the prosesses that are not chronologically connected.
                    Then we loop through all relevant prosesser and create a timeline step for the user to navigate.
                  */}
                  {prosesser.map((prosess) => (
                    <Step
                      className={`step ${
                        prosess.id === selectedProsess
                          ? 'step--active'
                          : 'step--inactive'
                      }
                        ${prosess.sortering < 0 ? 'step--negativesort' : ''}`}
                      key={prosess.id}
                    >
                      <StepButton
                        disabled={false}
                        onClick={() => handleSetActiveProsess(prosess.id)}
                      >
                        <StepLabel
                          StepIconComponent={() =>
                            prosessIcon(
                              prosess.id,
                              prosess.id === selectedProsess,
                              prosess.sortering < 0,
                            )
                          }
                          className="column--prosess-label">
                          {prosess.navn}
                        </StepLabel>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            );
          }
          /**
           * If the currenct fase is NOT the active fase.
           */
          return (
            <div
              key={fase.id}
              className="column column--faseIsSelected column--notTheActiveFase"
            >
              <Stepper
                className="stepper"
                alternativeLabel
                nonLinear
                activeStep={selectedFase}
                connector={<BufdirStepConnectorFaser/>}
              >

                <Step
                  className="step"
                  key={fase.id}
                  onClick={() => handleSetActiveFase(fase.id)}
                  tabIndex={0}
                  onKeyPress={(e) => handleEventSetActiveFase(e, fase.id)}
                >
                  <StepLabel className="step__title step__title--doNotDisplayIcon">
                    <h3>{fase.navn}</h3>
                  </StepLabel>
                  <StepLabel StepIconComponent={() => faseIcons(fase.id)}/>
                </Step>
              </Stepper>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Tidslinje;
