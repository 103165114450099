import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PROPTYPE_INNHOLDSELEMENTERLISTEWRAPPER } from '../../store/element-proptypes';

// eslint-disable-next-line import/no-cycle
import InnholdselementerListe from '../innholdselementer-liste/InnholdselementerListe';

/**
 * This component is used to handle the endless reference cycle risk. Instead of
 * writing this risk handling in every innholdselement, we use this wrapper
 * to do the checks.
 *
 *  type                = The type of innholdselement the list is displayed in
 *  id                  = ID of the innholdselement
 *  inExpandMode        = Tells the innholdselement if it is shown in an expanded window
 *  listLevel           = How many levels down in the reference hierarchy the innholdselement is displayed at
 *  innholdselementer   = The list of elements related to the wanted list
 *
 * @param {object} param0 Props to display the wrapper
 * @returns A component that is a wrapper for InnholdselementListe
 */
const InnholdsElementerListeWrapper = ({
  type,
  id,
  inExpandMode,
  listLevel,
  innholdselementer,
}) => (
  <>
    {inExpandMode && listLevel > 5 && (
      <div className="message is-danger">
        <div className="message-header">
          <p>Fare for uendelig syklus</p>
        </div>
        <div className="message-body">
          Nivået på tekstreferanser har nå oversteget 5 nivåer dypt og det er
          fare for at et eller flere elementer skaper en uendelig
          referansesyklus.
          <b>
            <p>Element-ID: {id}</p>
          </b>
          <b>
            <p>Element-type: {type}</p>
          </b>
        </div>
      </div>
    )}

    {/* SET UP A CHECK TO SEE IF LEVEL IS 2 AND 'tekstreferanser.length' IS MORE THAN ONE, THEN GIVE EXPAND SUGGESTION */}
    {((inExpandMode && listLevel < 6) || (!inExpandMode && listLevel < 2)) && (
      <div className={`${type}__innholdselementliste`}>
        <InnholdselementerListe
          innholdselementer={innholdselementer}
          listLevel={listLevel + 1}
          inExpandMode={inExpandMode}
        />
      </div>
    )}
    {!inExpandMode && listLevel > 1 && (
      <div className="message is-info">
        <div className="message-header">
          <p>Flere tekstreferanser finnes</p>
        </div>
        <div className="message-body">
          Det finnes ytterligere {innholdselementer.length} tilknyttede
          tekstreferanser. Åpne utvidet listevisning (&nbsp;
          <FontAwesomeIcon color="#737373" icon={['far', 'expand']} size="1x" />
          &nbsp;) for å vise disse.
        </div>
      </div>
    )}
  </>
);

InnholdsElementerListeWrapper.propTypes =
  PROPTYPE_INNHOLDSELEMENTERLISTEWRAPPER;

export default InnholdsElementerListeWrapper;
