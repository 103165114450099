import React, {useMemo, useState, useEffect} from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFileAlt,
  faHourglassStart,
  faHourglassEnd,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFileSignature,
  faHammerWar,
  faFileSearch,
  faExclamation,
  faQuoteRight,
  faExpand,
  faList,
  faBook,
  faBookmark,
  faArchive,
  faFilePowerpoint,
  faAnchor,
  faQuestion,
  faTriangle,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faLightbulb,
  faLightbulbSlash,
  faGlobe,
  faMapSigns,
} from '@fortawesome/pro-light-svg-icons';

import { ApolloWrapper } from './graphql/ApolloWrapper';
import About from './components/om-content/About'
import MainContent from './components/main-content/MainContent';
import Layout from './Layout';
import Store from './store/store';
import { Route, Routes } from 'react-router-dom';

import './App.scss';

/**
 * To be able to use FontAwesome icons in the application,
 * we must load 'library' with all the icons needed and we
 * then later in other components send the correct icon
 * information to FontAwesome components and the icons
 * with be fetched from library.
 */
library.add(
  faFileAlt,
  faQuestion,
  faHourglassStart,
  faHourglassEnd,
  faHammerWar,
  faFileSignature,
  faFileSearch,
  faExclamation,
  faQuoteRight,
  faExpand,
  faList,
  faBook,
  faBookmark,
  faArchive,
  faFilePowerpoint,
  faLightbulb,
  faLightbulbSlash,
  faGlobe,
  faTriangle,
  faAnchor,
  faTriangle,
  faMapSigns,
  faExclamationTriangle,
);

/**
 * To give all components access to the store variables, we wrap them in
 * the store componenet (<Store>).
 */
function App() {
  return (
      <Store>
        {/* <ApolloWrapper> */}
          <Layout>
            <div className="vv-container">
              <Routes>
                <Route path="/" element={ <MainContent />} />
                <Route path="/om" element={<About />} />
              </Routes>
            </div>
          </Layout>
        {/* </ApolloWrapper> */}
      </Store>
  );
}

export default App;
