import configFile from '../../env.json';

/**
 * This function returns a display friendly name for environment
 * @param {string} env Azure environment name
 * @returns String
 */
export const getEnvironmentName = (env) => {
  switch (env) {
    case 'env-bfkvv-dev':
      return 'Development';
    case 'env-bfkvv-qa':
      return 'Q&A';
    case 'env-bfkvv-prod':
      return 'Production';
    default:
      return 'Development';
  }
};

/**
 * This function returns the Azure Container Registry container-name based on environment
 * @param {string} env Azure environment name
 * @returns String
 */
export const getEnvironmentContainerName = (env) => {
  switch (env) {
    case 'env-bfkvv-dev':
      return 'bfkvv-dev';
    case 'env-bfkvv-qa':
      return 'bfkvv-qa';
    case 'env-bfkvv-prod':
      return 'bfkvv-prod';
    default:
      return 'bfkvv-dev';
  }
};

/**
 * This function is used to generate random IDs if needed.
 * @returns A random string ID.
 */
export const generateRandomId = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

/**
 * This regex is used to fetch the refType from 'refUri' in objects.
 * Ex: For 'Bufdir:bfk/kunnskapstekst/47' the regex will return
 * ["/kunnskapstekst/", "kunnskapstekst"], so we return index 1.
 * @param {string} refUri refUri value from an innholdselement
 */
export const getRefTypeFromRefUri = (refUri) => {
  const REFURI_REGEX = new RegExp(/\/([^/]+)\//g);
  const matches = REFURI_REGEX.exec(refUri);
  return matches[1];
};

/**
 * Helper method used for standard object sorting based on 'sortering' and 'id'.
 * @param {object} a First object
 * @param {object} b Second object
 * @returns A negative, 0 or a positive value.
 */
export const sortObjects = (a, b) => {
  // If a is negative and b is positive, place a after b
  if (a.sortering < 0 && b.sortering >= 0) {
    return 1;
  }
  // If a is positive and b is negative, dont change order
  if (b.sortering < 0 && a.sortering >= 0) {
    return -1;
  }
  // If both is negative, place the one closest to 0 in front
  if (a.sortering < 0 && b.sortering < 0) {
    return a.sortering * -1 - b.sortering * -1;
  }
  if (a.sortering === b.sortering) {
    return a.id - b.id;
  }
  return a.sortering - b.sortering;
};

/**
 * Helper method used to sort an array of innholdselementer based on refType.
 * Wanted order:
 *    1. Kilder
 *    2. Kunnskapstekst
 *    3. Spørsmål
 *    4. Liste
 * @param {object} a First object
 * @param {object} b Second object
 * @returns A negative, 0 or a positive value.
 */
export const sortObjectsBasedOnRefType = (a, b) => {
  const refTypeOrder = [
    'kilde',
    'kunnskapstekst',
    'sporsmal',
    'liste',
    'publikasjon',
  ];
  const aRefTypeOrderIndex = refTypeOrder.indexOf(a.refType);
  const bRefTypeOrderIndex = refTypeOrder.indexOf(b.refType);
  if (aRefTypeOrderIndex === bRefTypeOrderIndex) return a.card - b.card;
  return aRefTypeOrderIndex - bRefTypeOrderIndex;
};

export const sortObjectsBasedOnTeksttype = (a, b) => {
  const refTypeOrder = [
    'JuridiskKilde',
    'FagligKilde',
    'Hjelpetekst',
    'Fordypningstekst',
    'Veiledningstekst',
  ];
  const aRefTypeOrderIndex = refTypeOrder.indexOf(a.teksttype);
  const bRefTypeOrderIndex = refTypeOrder.indexOf(b.teksttype);
  if (aRefTypeOrderIndex === bRefTypeOrderIndex) return a.card - b.card;
  return aRefTypeOrderIndex - bRefTypeOrderIndex;
};

/**
 * This function fetches the basic values usually used in this application.
 * Instead of writing this map-method all of the code, we create a standard
 * function for it.
 * @param {object} obj The object we want to get the 'basic' values from.
 * @returns A object with the basic values used for an object.
 */
export const getObjectValues = (obj) => ({
  navn: obj.navn,
  id: obj.id,
  sortering: obj.sortering,
});

/**
 * Based on the prosess-id, the function returns an array with information
 * used by FontAwesome to print out the correct icon.
 * @param {string} id ID of a prosess
 * @returns An array on the correct format for FontAwesome to use to get the right icon.
 */
export const getProsessIcon = (id) => {
  switch (id) {
    case 6:
      return ['fas', 'file-alt'];
    case 36:
      return ['far', 'file-signature'];
    case 45:
      return ['far', 'hammer-war'];
    case 91:
      return ['fas', 'hourglass-start'];
    case 102:
      return ['far', 'file-search'];
    case 110:
      return ['fas', 'hourglass-end'];
    case 120:
      return ['far', 'question'];
    // TODO: Get a fallback icon
    default:
      return ['far', 'archive'];
  }
};

/**
 * As kunnskapstekst-type is only represented with an ID in the JSON file,
 * we have to build logic to fetch the correct type in string format.
 * @param {int} id ID for a certain type of kunnskapstekst.
 * @returns A string representing the correct type of kunnskapstekst.
 */
export const getTekstTypeString = (id) => {
  switch (id) {
    case 'Hjelpetekst':
      return 'Hjelpetekst';
    case 'Fordypningstekst':
      return 'Fordypningstekst';
    case 'Veiledningstekst':
      return 'Veiledningstekst';
    case 'JuridiskKilde':
      return 'Juridisk kilde';
    case 'FagligKilde':
      return 'Faglig kilde';

    default:
      return null;
  }
};

export const isKildeOfTypeJuridiskOrFaglig = (kildetype) => {
  if (kildetype === 'JuridiskKilde' || kildetype === 'FagligKilde') {
    return true;
  }
  return false;
};

/**
 * This function returns a color that belongs to a dimensjon based on its ID.
 * @param {number} dimensjonId ID of dimensjon
 * @returns Hex color in string
 */
export const getDimensjonColor = (isExpanded) => {
  if (isExpanded) {
    return '#067c6e';
  }
  return '#dbeae7';
};

/**
 * This function performes basic validation of file.
 * @param {string} json JSON file to be validated
 * @returns boolean
 */
export const validateData = (json) => {
  if (Object.keys(json).indexOf('bfk') === -1) {
    return false;
  }
  if (
    Object.keys(json.bfk).indexOf('kunnskapsmodell') === -1 ||
    Object.keys(json.bfk).indexOf('dimensjon') === -1 ||
    Object.keys(json.bfk).indexOf('omrade') === -1 ||
    Object.keys(json.bfk).indexOf('fase') === -1 ||
    Object.keys(json.bfk).indexOf('prosess') === -1 ||
    Object.keys(json.bfk).indexOf('oppgave') === -1 ||
    Object.keys(json.bfk).indexOf('liste') === -1 ||
    Object.keys(json.bfk).indexOf('listeelement') === -1 ||
    Object.keys(json.bfk).indexOf('sporsmal') === -1 ||
    Object.keys(json.bfk).indexOf('kunnskapstekst') === -1 ||
    Object.keys(json.bfk).indexOf('kilde') === -1 ||
    Object.keys(json.bfk).indexOf('publikasjon') === -1
  ) {
    return false;
  }
  return true;
};

/**
 * Fetches the environment-based variables
 * @returns object
 */
export const fetchEnvSettings = async () => {
  try {
    const envReq = await fetch(`${configFile}`);
    const fetchedEnvSettings = await envReq.json();
    return fetchedEnvSettings;
  } catch (error) {
    return null;
  }
};

/**
 * This function gets the file extension from the file name
 * @param {string} name File name
 * @returns string
 */
export const getExtension = (name) => {
  const parts = name.split('.');
  return parts[parts.length - 1];
};
