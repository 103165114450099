import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { PROPTYPE_DIMENSJON } from '../../store/element-proptypes';
import { getDimensjonColor } from '../../store/helper-methods';

import InnholdselementIkon from '../innholdselementikon/InnholdselementIkon';
import Omrader from '../omrader/Omrader';

import useStore from '../../store/use-store';
import { DevOnly, IsProdEnvironment } from '../predicate-display/PredicateDisplay';
/**
 * A Dimensjon is a part of Kunnskapsmodellen and renders out a list of omrader.
 *  dimensjon         = The dimensjon with props to display
 *  isExpanded        = Boolean saying if the dimensjon is expanded or not
 *  handleExpandFunc  = Handle function to toggle the dimensjon
 * @param {Props} param0 Props used to display a Dimensjon
 * @returns A dimensjon component
 */
const Dimensjon = ({ dimensjon, isExpanded, handleExpandFunc }) => {
  const { getOmrader } = useStore();
  const { id, navn, beskrivelse } = dimensjon;
  const [omrader, setOmrader] = useState([]);

  useEffect(() => {
    if (isExpanded) {
      const fetchedOmrader = getOmrader(id);
      setOmrader(fetchedOmrader);
    } else {
      setOmrader([]);
    }
  }, [isExpanded]);

  const getTooptip = () => (
    <>
      <p>ID: {id}</p>
      <p>Dimensjon</p>
    </>
  );

  return (
    <Accordion
      className={`dimensjonerliste__dimensjon dimensjonerliste__dimensjon--${id}`}
      expanded={isExpanded}
    >
      <Button target="_blank" key={id} onClick={() => handleExpandFunc(id)}>
        <ListItem>
          <Tooltip
            title={getTooptip()}
            placement="left"
            enterDelay={300}
            leaveDelay={0}
            disableFocusListener={IsProdEnvironment()}
            disableHoverListener={IsProdEnvironment()}
            disableTouchListener={IsProdEnvironment()}
          >
            <ListItemAvatar>
              <InnholdselementIkon
                elementType="dimensjon"
                elementObject={dimensjon}
                color={getDimensjonColor(isExpanded)}
              />
            </ListItemAvatar>
          </Tooltip>
          <ListItemText
            secondary={
              beskrivelse !== '' ? beskrivelse : 'Beskrivelse mangler!'
            }
          >
            {navn}
          </ListItemText>
        </ListItem>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <AccordionDetails>
        {omrader.length > 0 ? (
          <Omrader omrader={omrader} color={getDimensjonColor(id)} />
        ) : (
          <p>Dimensjonen inneholder dessverre ingen områder.</p>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

Dimensjon.propTypes = PROPTYPE_DIMENSJON;

export default Dimensjon;
