import React from 'react';

import {
  Button,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Card,
  Tooltip,
} from '@material-ui/core';

import {
  getTekstTypeString,
  isKildeOfTypeJuridiskOrFaglig,
} from '../../store/helper-methods';

import { PROPTYPE_KILDE } from '../../store/element-proptypes';

import InnholdselementIkon from '../innholdselementikon/InnholdselementIkon';
import InnholdselementWarningIcon from '../innholdselement-warning-icon/InnholdselementWarningIcon';
import { DevAndQaOnly, DevOnly, IsProdEnvironment } from '../predicate-display/PredicateDisplay';
import useStore from '../../store/use-store';

/**
 * This is a component to display a Kilde and is displayed
 * as a button with a external link connected to it.
 *  kilde             = The external link
 *  inExpandMode      = Tells the innholdselement if it is shown in an expanded window
 *  borderColor       = Border color of the element (for list level use)
 *  backgroundColor   = Background color for the element
 *  isDuplicate       = Boolean saying if there is an element with the same ID as this one
 * @param {Props} param0 Props to display the kilde
 * @returns A innholdselement of type Kilde
 */
const Kilde = ({
  kilde,
  inExpandMode,
  borderColor,
  backgroundColor,
  isDuplicate,
}) => {
  const { getTekstreferanse, getEnvironment } = useStore();
  const { id, url, teksttype, publikasjon } = kilde;
  let { navn } = kilde;
  const getTooptip = () => (
    <>
      <p>ID: {id}</p> 
      <p>{getTekstTypeString(teksttype)}</p>
    </>
  );
  const addWarningSign = () => {
    if (!isKildeOfTypeJuridiskOrFaglig(teksttype)) {
      return (
        <DevOnly>
          <InnholdselementWarningIcon tooltipText="Kontroller teksttype" />
        </DevOnly>
        );
    }
    return null;
  };

  if (publikasjon) {
    const fetchedPublikasjon = getTekstreferanse(publikasjon);
    if (fetchedPublikasjon) {
      navn = `${fetchedPublikasjon.navn} ${navn}`;
    }
  }
  return (
    <Card
      style={{
        border:
          borderColor !== 'none' ? `2px solid ${borderColor}` : borderColor,
        background: inExpandMode ? backgroundColor : 'none',
        backgroundColor: inExpandMode ? backgroundColor : 'none',
      }}
    >
      <Button
        href={url}
        target="_blank"
        key={id}
        style={{ backgroundColor: 'none' }}
      >
        <ListItem>
          <ListItemAvatar>
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <Tooltip
                title={getTooptip()}
                placement="left"
                enterDelay={300}
                leaveDelay={0}
                disableFocusListener={IsProdEnvironment()}
                disableHoverListener={IsProdEnvironment()}
                disableTouchListener={IsProdEnvironment()}
              >
                <span className="tooltip__kilde">
                  <InnholdselementIkon
                    elementType="kilde"
                    elementObject={kilde}
                    color="#737373"
                  />
                </span>
              </Tooltip>
              {addWarningSign()}
              {isDuplicate &&  
                <DevAndQaOnly>
                  <InnholdselementWarningIcon tooltipText="Dette elementet finnes flere steder i denne listen" />
                </DevAndQaOnly>
              }
            </span>   
          </ListItemAvatar>
          <ListItemText>{navn}</ListItemText>
        </ListItem>
      </Button>
    </Card>
  );
};

Kilde.propTypes = PROPTYPE_KILDE;

export default Kilde;
