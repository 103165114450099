import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';

import useStore from '../../store/use-store';

import ExtendedView from '../extended-view/ExtendedView';
import Dimensjoner from '../dimensjoner/Dimensjoner';

import kunnskapsmodellenAllImage from '../../statics/kunnskapsmodellen_all.png';
import kunnskapsmodellenBarnestUtviklingImage from '../../statics/kunnskapsmodellen_barnets_utvikling.png';
import kunnskapsmodellenForeldrenesOmsorgImage from '../../statics/kunnskapsmodellen_foreldres_omsorg.png';
import kunnskapsmodellenFamilieOgMiljoImage from '../../statics/kunnskapsmodellen_familie_og_miljo.png';

import './Kunnskapsmodellen.scss';

/**
 * This component dislays Kunnskapsmodellen with all the relevant Dimensjon and Omrade
 * @param {Object} param0 Props to display Kunnskapsmodellen panel
 * @returns A component that visualises Kunnskapsmodellen
 */
const Kunnskapsmodellen = ({ icon, title }) => {
  const { getKunnskapsmodell, getDimensjoner } = useStore();
  const [kunnskapsmodell, setKunnskapsmodell] = useState(null);
  const [dimensjoner, setDimensjoner] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedDimensjon, setExpandedDimensjon] = useState(false);

  /**
   * The first thing we do when the component has loaded is to
   * fetch all data for Kunnskapsmodellen.
   */
  useEffect(() => {
    const fetchedKunnskapsmodell = getKunnskapsmodell();
    setKunnskapsmodell(fetchedKunnskapsmodell);
  }, []);

  useEffect(() => {
    if (kunnskapsmodell) {
      const fetchedDimensjoner = getDimensjoner(kunnskapsmodell.id);
      setDimensjoner(fetchedDimensjoner);
    }
  }, [kunnskapsmodell]);

  const handleToggleExtendedView = () => {
    setIsExpanded(!isExpanded);
  };

  const getKunnskapsmodellenImage = () => {
    switch (expandedDimensjon) {
      case 17:
        return kunnskapsmodellenBarnestUtviklingImage;
      case 24:
        return kunnskapsmodellenForeldrenesOmsorgImage;
      case 29:
        return kunnskapsmodellenFamilieOgMiljoImage;
      default:
        return kunnskapsmodellenAllImage;
    }
  };

  /**
   * This function handles the expand/collapse of all dimensjoner.
   * Only one can be expanded at a time.
   * @param {string} id id of dimensjon
   */
  const handleDimensjonExpand = (id) => {
    setExpandedDimensjon(id === expandedDimensjon ? false : id);
  };

  const buildKunnskapsmodellen = () => {
    if (kunnskapsmodell) {
      return (
        <div className="kunnskapsmodellen">
          <div className="kunnskapsmodellen__image">
            <img
              src={getKunnskapsmodellenImage()}
              alt="Kunnskapsmodellen illustrasjon"
            />
          </div>
          <Dimensjoner
            dimensjoner={dimensjoner}
            expandedDimensjon={expandedDimensjon}
            handleDimensjonExpandFunc={handleDimensjonExpand}
          />
        </div>
      );
    }
    return null;
  };

  if (isExpanded) {
    return (
      <ExtendedView
        title={title}
        icon={icon}
        handleCloseFunc={() => handleToggleExtendedView()}
      >
        {buildKunnskapsmodellen()}
        {/* <InnholdselementerListe innholdselementer={content} inExpandMode /> */}
      </ExtendedView>
    );
  }

  return (
    <div className="innholdselementliste-container">
      {/* <Tooltip
        title="Utvidet listevisning"
        placement="top"
        enterDelay={300}
        leaveDelay={0}
      > */}
        <div className="innholdselementliste-container__expandIcon">
          <FontAwesomeIcon
            onClick={() => handleToggleExtendedView()}
            color="#737373"
            icon={['far', 'expand']}
            size="2x"
          />
        </div>
      {/* </Tooltip> */}
      {buildKunnskapsmodellen()}
      {/* <InnholdselementerListe innholdselementer={content} /> */}
    </div>
  );
};

Kunnskapsmodellen.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Kunnskapsmodellen;
