import React from 'react';
import useStore from '../../store/use-store';
import { validateData, getExtension } from '../../store/helper-methods';
import './FileUpload.scss';
import { DevOnly } from '../predicate-display/PredicateDisplay';

/**
 * This component is used to upload local files to display content in the application.
 * Before the file is inserted into the global store state, it is first validated
 * to see if it of correct type and format.
 * @returns A file upload component
 */
function FileUpload() {
  const { uploadJson, upsertErrors, filename, getEnvironment } = useStore();

  /**
   * This function is called when a user clicks the upload button.
   * First it validates the extension type. After that it validates the
   * content of the data. If the data in the file is valid, we upload it
   * to azure storage account.
   * @param {object} e event
   */
  const handleChange = (e) => {
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader();
    const fileName = e.target.files ? e.target.files[0].name : '';
    if (getExtension(fileName.toLowerCase()) !== 'json') {
      upsertErrors(
        'Feil filtype',
        'For filopplasting er det bare JSON-format som er tillatt.',
      );
      e.target.value = null;
    } else {
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (onloadEvent) => {
        let parsedJson = null;
        try {
          parsedJson = JSON.parse(onloadEvent.target.result);
          if (validateData(parsedJson)) {
            uploadJson(parsedJson.bfk, fileName);
          } else {
            upsertErrors(
              'Feil i fil',
              `Innholdet i JSON-filen '${fileName}' er ikke på riktig format.`,
            );
          }
        } catch (err) {
          if (err instanceof SyntaxError) {
            upsertErrors(
              'Feil i fil',
              `Innholdet i JSON-filen '${fileName}' er ikke på riktig format.`,
            );
          } else if (err instanceof ReferenceError) {
            upsertErrors(
              'Finner ikke fil',
              'Finner ikke filen på oppgitt plassering.',
            );
          } else {
            upsertErrors(
              'Ukjent problem',
              'Noe gikk galt ved opplasting. Ta kontakt med ansvarlig for å løse problemet.',
            );
            console.error(err);
          }
        }
        e.target.value = null;
      };
    }
  };
  return (
    <div className="file-upload columns">
      <div className="column">
        <DevOnly>
          <input type="file" onChange={handleChange} />
        </DevOnly>
      </div>
      <div className="column">
        {filename !== null ? (
          <h3>
            Viser JSON-fil: <b style={{ color: 'green' }}>{filename}</b>
          </h3>
        ) : (
          <h3 style={{ color: 'red' }}>Ingen JSON-fil lastet opp</h3>
        )}
      </div>
    </div>
  );
}

export default FileUpload;
