/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BlMenubutton } from 'buflib';
import FileUpload from '../../components/file-upload/FileUpload';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import './Header.scss';
import { DevOnly } from '../predicate-display/PredicateDisplay';
import useStore from '../../store/use-store';
/**
 * Use this component at the top of any web page.
 * The component uses the semantic html5 element \<header\>.
 * It should not be contained within a grid element,
 * but should be allowed to strech all the way from side to side.
 */

const Header = ({ langOpenMenu, langOpenMenuHint, logoUrl, func }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const {
    getVersions,
  } = useStore()
  const getMenuName = () => {
    switch(pathname) {
      case "/om": 
        return <a className='menu-link' onClick={() => { setIsOpenMenu(false); navigate("/")}}>Tilbake til BFK</a>
      case "/":
        return <a className='menu-link' onClick={() => { setIsOpenMenu(false); navigate("om")}}>Om BFK</a>
      default:
        return <a className='bl-link bl-size-5' onClick={() => { setIsOpenMenu(false); navigate("/")}}>Tilbake til BFK</a>
    }
  }

  const MenubuttonElem = (
    <div className="vv-header__trigger column is-1-tablet">
      <BlMenubutton
        langInactiveHint={langOpenMenuHint}
        langInactive={langOpenMenu}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
        isActive={isOpenMenu}
      />
    </div>
  );

  return (
    <>
      <header
        className={`vv-header bl-p-y-4 ${
          isOpenMenu ? 'vv-header--open' : 'vv-header--closed'
        }`}
      >
        <div className="vv-container">
          <div className="vv-header__topbar columns is-vcentered is-gapless is-multiline is-mobile">
            <a
              href="https://bufdir.no"
              className="vv-header__logo column is-10-mobile is-2-tablet"
            >
              <img src={logoUrl} alt="Logo" />
              <div className='vv-title'>
                Barnevernsfaglig kvalitetssystem
              </div>
             
            </a>
            <div>
              <DevOnly>
                <div className= ''>
                  <FormControl fullWidth={false}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Versjon
                    </InputLabel>
                    <NativeSelect
                      onChange={(e) => func({version: e.target.value})}
                      defaultValue={''}
                      inputProps={{
                        name: 'version',
                        id: 'uncontrolled-native',
                      }}
                    >
                      { getVersions().map(v => <option value={v}>{v}</option>)}
                     
                    </NativeSelect>
                  </FormControl>
                </div>
              </DevOnly>
              {MenubuttonElem}
            </div>  
           
          </div>
        </div>
        <div
          className={`vv-header__megamenu ${
            isOpenMenu
              ? 'vv-header__megamenu--open'
              : 'vv-header__megamenu--closed'
          }`}
        >
          <div className="vv-container">
              <div className="columns">
                  <div className="column bl-p-a-3 bl-bg-sand-4 bl-shadow--large bl-shadow--sand-3">
                    <FileUpload />
                  </div>       
              </div>
              <div className='columns'>
              
                </div>
              <div className="columns">
                <div className="menu-link-column">
                    { getMenuName() }
                </div> 
              </div>
          </div>
        </div>
      </header>

      {isOpenMenu && (
        <div
          onClick={() => setIsOpenMenu(!isOpenMenu)}
          className="vv-background-box"
        />
      )}
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * url to logo
   * */
  logoUrl: PropTypes.string.isRequired,
  /**
   * Set's the menu button text
   * */
  langOpenMenu: PropTypes.string,
  /**
   * Set's the menu button hint on hoover text
   * */
  langOpenMenuHint: PropTypes.string,
  /**
   * Url to search result page.
   * */
};

Header.defaultProps = {
  langOpenMenu: 'Meny',
  langOpenMenuHint: 'Åpne meny',
};

export default Header;
