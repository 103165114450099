/* eslint-disable dot-notation */
import { BlobServiceClient } from '@azure/storage-blob';
import { fetchEnvSettings } from './helper-methods';

/**
 * Based on the environment from file, we return the correct container name.
 * @returns string
 */
const getContainerName = async () => {
  const envSettings = await fetchEnvSettings();
  if (envSettings && envSettings.ENVIRONMENT_CONTAINER_NAME) {
    return envSettings.ENVIRONMENT_CONTAINER_NAME;
  }
  return null;
};

/**
 * Based on the environment from file, we return the correct container name.
 * @returns string
 */
const getStorageAccountName = async () => {
  const envSettings = await fetchEnvSettings();
  if (envSettings && envSettings.STORAGE_ACCOUNT_NAME) {
    return envSettings.STORAGE_ACCOUNT_NAME;
  }
  return null;
};

/**
 * Based on the environment the user is in we return the correct SAS token
 * @returns string
 */
const getSASTokenBasedOnEnv = async () => {
  const envSettings = await fetchEnvSettings();
  if (envSettings && envSettings.SAS_TOKEN) {
    return envSettings.SAS_TOKEN;
  }
  return null;
};

/**
 * This function converts a blob (file) to a string that can easily be used.
 * @param {file} blob File to convert
 * @returns string
 */
const blobToString = async (blob) => {
  // eslint-disable-next-line no-undef
  const fileReader = new FileReader();
  const fileAsString = new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
  return fileAsString;
};

/**
 * Returns a boolean indication if configurations is missing or not.
 * @returns Boolean
 */
export const isStorageConfigured = async () => {
  const storageAccountName = await getStorageAccountName();
  const containerName = await getContainerName();
  const token = await getSASTokenBasedOnEnv();
  if (!storageAccountName || !containerName || !token) {
    return false;
  }
  return true;
};

/**
 * Establishes a connection instance with azure blob storage
 * @returns BlobServiceClient
 */
const createContainerClient = async () => {
  const storageAccountName = await getStorageAccountName();
  const containerName = await getContainerName();
  const token = await getSASTokenBasedOnEnv();
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${token}`,
  );

  // Get container
  const containerClient = blobService.getContainerClient(containerName);
  return containerClient;
};

/**
 * Fetch and returns the newest updated file in the azure storage account
 * @returns string | null
 */
const getStandardFileInContainer = async () => {
  const returnedBlobs = [];
  const containerClient = await createContainerClient();
  let listOfBlobs = [];

  try {
    listOfBlobs = containerClient.listBlobsFlat();
  } catch (error) {
    return null;
  }

  let blobFromList = null;
  try {
    blobFromList = await listOfBlobs.next();
    while (!blobFromList.done) {
      const item = blobFromList.value;
      returnedBlobs.push(item);
      blobFromList = await listOfBlobs.next();
    }
  } catch (error) {
    return null;
  }

  if (returnedBlobs.length > 0) {
    if (returnedBlobs.length > 1) {
      returnedBlobs.sort((a, b) => {
        if (a && b && a.properties && b.properties) {
          return b.properties.lastModified - a.properties.lastModified;
        }
        return 0;
      });
    }
    try {
      // Fetch file based on name
      const blobClient = containerClient.getBlobClient(returnedBlobs[0].name);
      // Download the file
      const downloadBlockBlobResponse = await blobClient.download();
      // Converts to string
      const downloaded = await blobToString(
        await downloadBlockBlobResponse.blobBody,
      );
      return { name: returnedBlobs[0].name, file: downloaded };
    } catch (error) {
      console.log('Could not download file from storage account.', error);
      return null;
    }
  }
  console.log('No file in storage account.');
  return null;
};
export default getStandardFileInContainer;
