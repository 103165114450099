import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';

import useStore from '../../store/use-store';

import ExtendedView from '../extended-view/ExtendedView';
import InnholdselementerListe from '../innholdselementer-liste/InnholdselementerListe';

/**
 * This is the wrapper for Globale Kunnskapstekster.
 *  title = Title of the panel
 *  icon = The icon for the panel
 * @param {Props} param0 Props used to display Globale Kunnskapstekster panel
 * @returns A frame containing innholdselementer for Globale Kunnskapstekster
 */
const GlobaleKunnskapstekster = ({ icon, title }) => {
  const { selectedFase, selectedProsess, getGlobaleKunnskapstekster } =
    useStore();
  const [content, setContent] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  /**
   * Globale Kunnskapstekster does not fetch content on a lower level than prosess.
   * So even though the user is selecting oppgave or spørsmål, this list will only be
   * limited to showing content related to the selected fase and prosess.
   */
  useEffect(() => {
    const fetchedInnholdselementer = getGlobaleKunnskapstekster();
    setContent(fetchedInnholdselementer);
  }, [selectedFase, selectedProsess]);

  const handleToggleExtendedView = () => {
    setIsExpanded(!isExpanded);
  };

  if (isExpanded) {
    return (
      <ExtendedView
        title={title}
        icon={icon}
        handleCloseFunc={() => handleToggleExtendedView()}
      >
        <InnholdselementerListe innholdselementer={content} inExpandMode />
      </ExtendedView>
    );
  }

  return (
    <div className="innholdselementliste-container">
      {/* <Tooltip
        title="Utvidet listevisning"
        placement="top"
        enterDelay={300}
        leaveDelay={0}
      > */}
        <div className="innholdselementliste-container__expandIcon">
          <FontAwesomeIcon
            onClick={() => handleToggleExtendedView()}
            color="#737373"
            icon={['far', 'expand']}
            size="2x"
          />
        </div>
      {/* </Tooltip> */}
      <InnholdselementerListe innholdselementer={content} />
    </div>
  );
};

GlobaleKunnskapstekster.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default GlobaleKunnskapstekster;
