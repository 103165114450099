import { gql } from "@apollo/client";

/* 
*  Query to fetch all json data from redigeringsverktøyet   
*/
export const GET_JSON = gql`
    query GetVersionVV($version: String) {
        versionVV(version: $version) {
            data
            schema
            validationErrors
        }
    }
    `;

export const GET_VERSIONS = gql `
    query VersionsVV {
        versionsVV
    }
`