import React from 'react';
import PropTypes from 'prop-types';

import './FeedbackMessage.scss';

/**
 * This is a component used to display feedback messages to the user.
 * At the moment the application only sends in error messages, but it is
 * open to display seven different types of message boxes.
 *
 *  messageType     = The type of message box
 *  header          = The header displayed for the box
 *  message         = The body message for the box
 *  deleteFunction  = The delete function that contains the feedback ID
 *
 * @param {object} param0 Props containing all relevant props for Feedback message.
 * @returns A feedback message element.
 */
const FeedbackMessage = ({ messageType, header, message, deleteFunction }) => (
  <article className={`message is-${messageType}`}>
    <div className="message-header">
      <p>{header}</p>
      <button
        type="button"
        className="delete"
        aria-label="delete"
        onClick={() => deleteFunction()}
      />
    </div>
    <div className="message-body">{message}</div>
  </article>
);

FeedbackMessage.propTypes = {
  /**
   * Type of message (determines the color of the box)
   * */
  messageType: PropTypes.oneOf([
    'dark',
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger',
  ]).isRequired,
  /**
   * Header displayed to the user
   * */
  header: PropTypes.string.isRequired,
  /**
   * Message displayed to the user
   * */
  message: PropTypes.string.isRequired,
  /**
   * Function to delete the message
   * */
  deleteFunction: PropTypes.func.isRequired,
};

export default FeedbackMessage;
