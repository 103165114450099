import PropTypes from 'prop-types';

const PROPTYPE_OBJEKTREFERANSE = PropTypes.shape({
  refId: PropTypes.string.isRequired,
  refType: PropTypes.string.isRequired,
  refUri: PropTypes.string.isRequired,
});

export const PROPTYPE_REQUIRED_PROPERTIES = PropTypes.shape({
  id: PropTypes.number.isRequired,
  uri: PropTypes.string.isRequired,
  navn: PropTypes.string.isRequired,
  beskrivelse: PropTypes.string.isRequired,
  sortering: PropTypes.number.isRequired,
});

export const PROPTYPE_INNHOLDSELEMENT_ICON = {
  elementType: PropTypes.string.isRequired,
  elementObject: PropTypes.shape({
    ...PROPTYPE_REQUIRED_PROPERTIES,
    teksttype: PropTypes.number,
  }),
  color: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export const PROPTYPE_OPPGAVESPORSMAL_ICON = {
  svarDataType: PropTypes.string.isRequired,
};

export const PROPTYPE_KILDE = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  teksttype: PropTypes.number.isRequired,
  forfatter: PropTypes.string,
  utgiver: PropTypes.string,
  utgivelsesar: PropTypes.string,
  utgivelsessted: PropTypes.string,
  url: PropTypes.string,
  hentet: PropTypes.string,
  publikasjon: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
};

export const PROPTYPE_KUNNSKAPSTEKST = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  teksttype: PropTypes.number.isRequired,
  tekst: PropTypes.string,
};

export const PROPTYPE_LISTE = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  begrep: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  listeelementer: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
};

export const PROPTYPE_SPORSMAL = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  begrep: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  svardatatype: PropTypes.number,
  svaralternativ: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
};

export const PROPTYPE_OPPGAVE = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  begrep: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  sporsmal: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  dimensjoner: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  omrader: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  lister: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  isOpen: PropTypes.bool.isRequired,
  handleExpandFunc: PropTypes.func.isRequired,
  oppgavetype: PropTypes.number.isRequired,
};

export const PROPTYPE_PUBLIKASJON = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  begrep: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  utgiver: PropTypes.string,
  url: PropTypes.string,
};

export const PROPTYPE_OMRADE = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  begrep: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
};

export const PROPTYPE_OMRADER = {
  omrader: PropTypes.arrayOf(PROPTYPE_OMRADE),
};

export const PROPTYPE_DIMENSJON = {
  ...PROPTYPE_REQUIRED_PROPERTIES,
  emner: PropTypes.arrayOf(PropTypes.string),
  tekstreferanser: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  begrep: PropTypes.arrayOf(PROPTYPE_OBJEKTREFERANSE),
  omrader: PropTypes.arrayOf(PROPTYPE_OMRADE),
};

export const PROPTYPE_DIMENSJONER = {
  dimensjoner: PropTypes.arrayOf(PROPTYPE_DIMENSJON),
};

export const PROPTYPE_INNHOLDSELEMENTERLISTEWRAPPER = {
  type: PropTypes.string,
  id: PropTypes.number,
  inExpandMode: PropTypes.bool,
  listLevel: PropTypes.number,
};
