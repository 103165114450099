import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlFooter } from 'buflib';
import Header from './components/header/Header';
import './Layout.scss';
import FileUpload from './components/file-upload/FileUpload';
import FeedbackMessage from './components/feedback-message/FeedbackMessage';
import { GET_JSON, GET_VERSIONS } from './graphql/Queries';
import { useQuery, gql } from '@apollo/client';
import useStore from './store/use-store';
import { validateData, fetchEnvSettings } from './store/helper-methods';
import { DevOnly, IsProdEnvironment } from './components/predicate-display/PredicateDisplay';

import QAWatermark from './statics/QA.png';
import DEVWatermark from './statics/DEV.png';
import { useNavigate, useLocation } from 'react-router-dom';

// SA-Load
import getStandardFileInContainer, {
  isStorageConfigured,
} from './store/azure-storage-blob';

function Layout({ children }) {
  // State used to set env-based variables
  const [envSettings, setEnvSettings] = useState(null);
  const [storageConfigured, setStorageConfigured] = useState(null);

  const {
    activeFile,
    hasUploaded,
    errors,
    isLoadingStandardFile,
    deleteError,
    setIsLoadingStandardFile,
    setStandardFile,
    setVersions,
    getVersions,
    setEnvironment,
    getSelectedVersion
  } = useStore();

  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(async () => {
    const config = await isStorageConfigured();
    setStorageConfigured(config);
  }, []);

  /**
   * Function to see if env requires watermark
   */
  const needsWatermark = () => {
    if (envSettings && envSettings.ENVIRONMENT) {
      return (
        envSettings.ENVIRONMENT === 'env-bfkvv-dev' || 
        envSettings.ENVIRONMENT === 'env-bfkvv-qa'
      );
    }
    return false;
  };

  /**
   * If watermark is needed, we return a watermark based on the env.
   */
  const getWatermarkBasedOnEnv = () => {
    if (envSettings.ENVIRONMENT === 'env-bfkvv-dev') {
      return DEVWatermark;
    }
    if (envSettings.ENVIRONMENT === 'env-bfkvv-qa') {
      return QAWatermark;
    }
    return null;
  };

    /**
   * On page load, we fetch the list of blobs in the container.
   */
    const fetchBlobsFromContainer = async () => {
      setIsLoadingStandardFile(true);
      const blobData = await getStandardFileInContainer();
      if (blobData && blobData.file) {
        const blobAsJson = JSON.parse(blobData.file);
        if (validateData(blobAsJson)) {
          setStandardFile(blobData.name, blobAsJson.bfk);
        } else {
          setStandardFile(null);
        }
      } else {
        setStandardFile(null);
      }
      setIsLoadingStandardFile(false);
    };

  useEffect(async () => {
    const fetchedEnvSettings = await fetchEnvSettings();
    setEnvironment(fetchedEnvSettings.ENVIRONMENT)
  }, [])

  // Fetch blobs on page load
  useEffect(async () => {
    if (storageConfigured) { 
      fetchBlobsFromContainer();
      const fetchedEnvSettings = await fetchEnvSettings();
      setEnvSettings(fetchedEnvSettings);
    }
  }, [storageConfigured]);

  // const { loading, error, data, refetch, networkStatus } =  useQuery(GET_JSON, {
  //   variables: { version: getSelectedVersion() },
  //   notifyOnNetworkStatusChange: true,
  // })
  
  // const { loading: versionLoading, error: versionError, data: versionData } = useQuery(GET_VERSIONS);

  // useEffect( () => {
  //   refetch({version: getSelectedVersion()})
  // }, [getSelectedVersion()])

  // useEffect( () => {
  //   if (versionData){
  //     setVersions(versionData.versionsVV)
  //   }
  // }, [versionData])

  // useEffect ( () => {
  //   if (data){
  //     const s = JSON.parse(data.versionVV.data)
  //     setStandardFile(s.bfk.versjon ?? "", s.bfk)
  //   }
  // }, [data])

  return (
    <div className="vv-grid-wrapper layout">
      <div
        className="vv-grid-main"
        style={{
          backgroundImage: needsWatermark()
            ? `url("${getWatermarkBasedOnEnv()}")`
            : 'none',
          backgroundRepeat: 'repeat',
        }}
      >   
        <div className="bl-p-b-4">
          <Header
            searchPageUrl="https://bufdir.no/sok/#stq="
            logoUrl="https://data.bufdir.no/images/logo_bufdir.svg"
            // func={refetch}
          >
          </Header>
        </div>
        {errors.length > 0 && (
          <div className="columns">
            <div className="column">
            {errors.map((err) => (
                <FeedbackMessage
                  key={err.id}
                  messageType="danger"
                  header={err.title}
                  message={err.msg}
                  deleteFunction={() => deleteError(err.id)}
                />
              ))}
            </div>
          </div>
        )}
        {hasUploaded && children}
        {/* {loading && ( */}
        {isLoadingStandardFile && (
          <>
            <progress className="progress is-small is-info" max="100">
              15%
            </progress>
            <div className="message is-info">
              <div className="message-header">
                <p>
                  <FontAwesomeIcon
                    color="#FFFFFF"
                    size="2x"
                    icon={['far', 'exclamation-triangle']}
                  />
                  Henter fil
                </p>
              </div>
              <div className="message-body">
                BFKvv jobber med å hente siste godkjente versjon fra databasen…
              </div>
            </div>
          </>
        )}
        {/* {error && !loading && ( */}
        <DevOnly>
          {!hasUploaded && !isLoadingStandardFile && (
            <div className="message is-info">
              <div className="message-header">
                <p>
                  <FontAwesomeIcon
                    color="#FFFFFF"
                    size="2x"
                    icon={['far', 'exclamation-triangle']}
                  />
                  Ingen fil lastet opp
                </p>
              </div>
              <div className="message-body">
                {/* {error.message} */}
                Trykk på <i>Meny</i> og last opp en fil for å vise og validere
                innhold fra barnevernsfaglig kvalitetsystem (BFK).
              </div>
            </div>
          )}
        </DevOnly>
        {!activeFile && !isLoadingStandardFile && (
          <div className="message is-info">
            <div className="message-header">
              <p>
                <FontAwesomeIcon
                  color="#FFFFFF"
                  size="2x"
                  icon={['far', 'exclamation-triangle']}
                />
                Klarte ikke hente fil fra databasen
              </p>
            </div>
            <div className="message-body">
              Vi klarte ikke hente fil fra databasen. 
              <DevOnly>
              Årsaken kan være at administrator ikke har lastet opp fil eller en utløpt access token.
              </DevOnly>
            </div>
          </div>
        )}
        {/* {activeFile && children} */}
      </div>
      <div className="vv-grid-bottom bl-p-t-6">
        <BlFooter
          title="Barne-, ungdoms- og familiedirektoratet"
          logoUrl="https://data.bufdir.no/images/logo_bufdir.svg"
          links={[
            { url: '#', title: 'Kontakt Oss' },
            { url: '#', title: 'Administrer cookies' },
            { url: '#', title: 'Personværnerklæring' },
            { url: '#', title: 'Personvernombud' },
          ]}
          containerWidth={1200}
          content="<p>Test</p>"
          hasToTop={false}
        >
          <p>
            <strong>Postadresse:</strong>
            <br />
            Postboks 2233, 3103 Tønsberg
          </p>
          <br />
          <p>
            <strong>Besøksadresse:</strong>
            <br />
            Fredrik Selmers vei 3, Oslo
          </p>
        </BlFooter>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
