import React, { useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  standardFile: null,
  isLoadingStandardFile: false,
  bfkJson: null,
  filename: null,
  hasUploaded: false,
  errors: [],
  selectedFase: -1,
  selectedProsess: -1,
  selectedOppgave: -1,
  selectedSporsmal: -1,
  currentUserLevel: 0,
  environment: "",
  versions: [],
  selectedVersion: null
};

export const Context = React.createContext();

const Store = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <Context.Provider value={[state, setState]}>{children}</Context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Store;
