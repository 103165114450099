import React from 'react';

import { PROPTYPE_DIMENSJONER } from '../../store/element-proptypes';

import Dimensjon from '../innholdselementer/Dimensjon';

import './Dimensjoner.scss';

/**
 * This component renders out a list of dimensjoner.
 *  dimensjoner                 = The relevant dimensjoner that is to be displayed in the list
 *  expandedDimensjon           = The id of the dimensjon that is expanded
 *  handleDimensjonExpandFunc   = The handle function for expanding dimensjoner
 * @param {object} param0 Props used to display the dimensjoner list
 * @returns A list component containing relevant dimensjoner
 */
const Dimensjoner = ({
  dimensjoner,
  expandedDimensjon,
  handleDimensjonExpandFunc,
}) => (
  <>
    <ul className="dimensjonerliste">
      {dimensjoner &&
        dimensjoner.length > 0 &&
        dimensjoner.map((d) => (
          <Dimensjon
            key={d.id}
            dimensjon={d}
            isExpanded={d.id === expandedDimensjon}
            handleExpandFunc={(id) => handleDimensjonExpandFunc(id)}
          />
        ))}
    </ul>
  </>
);

Dimensjoner.propTypes = PROPTYPE_DIMENSJONER;

export default Dimensjoner;
