/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { PROPTYPE_OPPGAVE } from '../../store/element-proptypes';

import useStore from '../../store/use-store';

import './StoppPunkt.scss';

/**
 * This is a special case of oppgavetype which will be displayed in
 * a different way than a normal oppgave.
 *  oppgave           = Props to be displayed
 *  isOpen            = Boolean indicating if the oppgave is expanded
 *  handleExpandFunc  = Handle function to expand the oppgave
 * @param {Object} param0 Props to display StoppPunkt
 * @returns A oppgave of type StoppPunkt
 */
const StoppPunkt = ({ oppgave, isOpen, handleExpandFunc }) => {
  const { getSporsmalForOppgave, setSelectedOppgave } = useStore();
  const { id, beskrivelse } = oppgave;

  const fetchedSporsmal = getSporsmalForOppgave(id);

  return (
    <fieldset 
      className="stopp-punkt-card"
      onClick={() => setSelectedOppgave(id)}>
      <legend
        role="listitem"
        // tabIndex="0"
      >
        <FontAwesomeIcon color="#79262e" icon={['far', 'anchor']} size="2x" />
        <span>STOPP-PUNKT</span>
      </legend>
      <div className="stopp-punkt-card__content">{beskrivelse}</div>
      {isOpen && fetchedSporsmal.length > 0 && (
        <div className="stopp-punkt-card__sporsmal">
          {fetchedSporsmal.map((sp) => (
            <p key={sp.id}>
              <FontAwesomeIcon
                color="#737373"
                icon={['far', 'quote-right']}
                size="1x"
              />
              <span>{sp.navn}</span>
            </p>
          ))}
        </div>
      )}
      {fetchedSporsmal.length > 0 && (
        <span
          onClick={() => handleExpandFunc(!isOpen)}
          onKeyPress={() => handleExpandFunc(!isOpen)}
          role="button"
          className="stopp-punkt-card__expand"
        >
          Vis spørsmal {isOpen ? <ExpandLess /> : <ExpandMore />}
        </span>
      )}
    </fieldset>
    
  );
};

StoppPunkt.propTypes = PROPTYPE_OPPGAVE;

export default StoppPunkt;
